import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportExpenseDetails = (props) => {
  const serverpage = "reportexpensedetails"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [expenseTypeList, setExpenseTypeList] = useState(null);
  
  const [userList, setUserList] = useState(null);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState(0);
  const [selectedExpenseUserId, setSelectedExpenseUserId] = useState(0);
  const [SubHeader, setSubHeader] = useState({
    Amount: 0
  });
  
  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const PrintPDFExcelExportFunction = (reportType) => {
    const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
    let finalUrl = EXCEL_EXPORT_URL + "report/expense_details_report_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        serverpage +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&ExpenseTypeId=" +
        selectedExpenseTypeId +
        "&ExpenseUserId=" +
        selectedExpenseUserId +
        "&SubHeader=" +
        JSON.stringify(SubHeader) +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      // TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
      ExpenseTypeId: selectedExpenseTypeId,
      ExpenseUserId: selectedExpenseUserId,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    getExpenseTypeList();
    getUserList();
    getDataList(); //invoice list
    setBFirst(false);
  }

  function getExpenseTypeList() {
    let params = {
      action: "ExpenseTypeList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setExpenseTypeList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }


  
  function getUserList() {
    let params = {
      action: "UserList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setUserList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Voucher Date",
      width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InvoiceNo",
      label: "Voucher No",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "ExpenseDate",
      label: "Expense Date",
      width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "ExpenseType",
      label: "Expense Type",
      width: "12%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
  
    {
      field: "ExpenseBy",
      label: "Expense By",
      width: "10%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "PayTo",
      label: "Pay To",
      width: "10%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "Comments",
      label: "Details",
      // width: "10%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "Amount",
      label: "Amount",
      width: "8%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

  ];

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const handleChangeFilterDropDown = (name, value) => {
    if (name == "ExpenseTypeId") {
      setSelectedExpenseTypeId(value);
    } 
    else if (name == "ExpenseUserId") {
      setSelectedExpenseUserId(value);
    }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate, selectedExpenseTypeId, selectedExpenseUserId]);

  useEffect(() => {
    let Amount = 0;

    dataList.forEach((row) => {
      // console.log('row: ', row);
      Amount += row.Amount;
    });

    Amount = new Intl.NumberFormat(["eng", "id"]).format(Amount);

    setSubHeader({
      Amount: Amount,
    });
  }, [dataList]);


  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Expense Details
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        <div class="searchAdd">
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>


          <div>
            <label class="pl-10">Expense Type</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ExpenseTypeId"
              name="ExpenseTypeId"
              autoComplete
              options={expenseTypeList ? expenseTypeList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ExpenseTypeId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>


          <div>
            <label class="pl-10">Expense By</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ExpenseUserId"
              name="ExpenseUserId"
              autoComplete
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ExpenseUserId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>
          {/* <div>
            <label class="pl-10">Billing Person</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="UserId"
              name="UserId"
              autoComplete
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "UserId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div> */}

          {/* <Button disabled={permissionType} label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button
            label={"Export"}
            class={"btnPrint"}
            onClick={PrintPDFExcelExportFunction}
          />
        </div>


        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
          Total Amount: <span class="font-bold">{SubHeader.Amount}</span>
          </label>


        </div>

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={masterColumnList}
              rows={dataList ? dataList : {}}
              // actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportExpenseDetails;
