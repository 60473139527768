import React, { forwardRef, useRef, useContext, useEffect } from "react";
import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";
import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportReceiveReturn = (props) => {
  const serverpage = "reportreceivereturn"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const baseUrl = process.env.REACT_APP_FRONT_URL;
  const [previewImage, setPreviewImage] = useState(
    `${baseUrl}image/receive/placeholder.png`
  );

  const [previewImages, setPreviewImages] = useState({
    ImageUrl: `${baseUrl}image/receive/placeholder.png`,
  });

  const [SubHeader, setSubHeader] = useState({
    Total: 0
  });

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const {
    isLoading: isLoadingSingle,
    data: dataListSingle,
    error: errorSingle,
    ExecuteQuery: ExecuteQuerySingle,
  } = ExecuteQueryHook(); //Fetch data for single

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
        "?action=ReportReceiveReturnExport" +
        "&reportType=excel" +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&TimeStamp=" +
        Date.now()
    );
  };
 
  /* =====End of Excel Export Code==== */
  

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 5,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */

    getDataList(); //invoice list

    setBFirst(false);
  }
 
  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Date",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InvoiceNo",
      label: "Return Invoice No.",
      width: "15%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SupplierName",
      label: "Supplier",
      align: "left",
      // width: "30%",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "NetPaymentAmount",
      label: "Total",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
   
  ];
   

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    // console.log("value: ", value);
    // console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate]);

  
  // const [SubHeader, setSubHeader] = useState({InvPrice:0,DiscAmt:0,VatAmt:0,VoidAmt:0,NetAmount:0,TotalCost:0,Profit:0});
  useEffect(() => {
    let Total = 0;

    dataList.forEach((row) => {
      // console.log('row: ', row);
      Total += row.NetPaymentAmount;
    });

    Total = new Intl.NumberFormat(["eng", "id"]).format(Total);

    setSubHeader({
      Total: Total,
    });
  }, [dataList]);

  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Stock Return Report
          </h4>
        </div>

          <>
            {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
            <div class="searchAdd">
              <label class="pl-10">Start Date</label>
              <div>
                <input
                  type="date"
                  id="StartDate"
                  name="StartDate"
                  value={StartDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>

              <label class="pl-10">End Date</label>
              <div>
                <input
                  type="date"
                  id="EndDate"
                  name="EndDate"
                  value={EndDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>

              <Button
                label={"Export"}
                class={"btnPrint"}
                onClick={PrintPDFExcelExportFunction}
              />
         
            </div>

            
        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
            Total: <span class="font-bold">{SubHeader.Total}</span>
          </label>
        </div>

            {/* <!-- ####---Master invoice list---####s --> */}
            <div class="subContainer">
              <div className="App">
                <CustomTable
                  columns={masterColumnList}
                  rows={dataList ? dataList : {}}
                />
              </div>
            </div>
          </>

      </div>
    </>
  );
};

export default ReportReceiveReturn;
