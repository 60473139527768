import React from "react";
import moment from "moment";

const SalesReturnToPrint = React.forwardRef((props, ref) => {
  console.log('SalesReturnToPrint props: ', props);
  return (
    <div ref={ref} className="print-invoice invoice-hidden">
      <div class="invoicePrintHeader">
        {/* <span className="text-bold">NextGen Pharma Ltd.</span> */}
        <span className="text-bold">{props.UserInfo.BranchName}</span>
        {/* <span>Sheikh Hasina Medical College & Hospital Road, Sabalia, Tangail</span> */}
        <span className="text-center">{props.UserInfo.BranchAddress}</span>
        {/* <span>Sabalia, Tangail</span> */}
        {/* <span>Mobile: 01327-233889</span> */}
        <span>Mobile: {props.UserInfo.PhoneNo}</span>
        <span className="text-bold">Sales Return Invoice No: {props.master.InvoiceNo}</span>
        <div class="invoiceInfo">
          <span className="text-left">Date: {moment().format('DD/MM/YY-h:mm:ss')}</span>
          <span className="text-right">Return By: {(props.userList && props.master.ServiceBy)?props.userList.filter((list)=>list.id==props.master.ServiceBy)[0].name : ""}</span>
        </div>
      </div>
      <div class="invoicePrintBody">
        <table>
          <tbody>
            <tr>
              <th className="text-left">SL</th>
              <th className="text-left">Return Item</th>
              <th className="text-right">Price</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Total</th>
            </tr>

            {props.many.map((row, index) => {
              return (
                <tr key={index}>
                  <td className="text-left">{index + 1 + "."}</td>
                  {/* <td className="text-left">{row.ProductName.split("(")[0]}</td> */}
                  <td className="text-left">{row.ProductNamePrint}</td>
                  <td className="text-right">{parseFloat(row.MRP?row.MRP:0).toFixed(2)}</td>
                  <td className="text-right">{row.Quantity}</td>
                  {/* <td className="text-right">{typeof row.SubTotalAmount == 'number'?row.SubTotalAmount.toFixed(2):0.00}</td> */}
                  <td className="text-right">{parseFloat(row.SubTotalAmount?row.SubTotalAmount:0).toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="invoicePrintCalculation">
        <span className="text-end text-bold">Total:</span>
        <span className="text-end text-bold">
          { parseFloat(props.master.SumSubTotalAmount?props.master.SumSubTotalAmount:0).toFixed(2)}
        </span>
        <span className="text-end">VAT(+):</span>
        <span className="text-end">{parseFloat(props.master.SumVatAmount?props.master.SumVatAmount:0).toFixed(2)}</span>
        <span className="text-end">Discount(-):</span>
        <span className="text-end">{(parseFloat(props.master.SumDiscountAmount?props.master.SumDiscountAmount:0)+parseFloat(props.master.SumCommission?props.master.SumCommission:0)).toFixed(2)}</span>
        <hr style={{ width: "100%" }} />
        <hr style={{ width: "100%" }} />
        <span className="text-end text-bold">Net Payble:</span>
        <span className="text-end text-bold">
          {parseFloat(props.master.NetPaymentAmount?props.master.NetPaymentAmount:0).toFixed(2)}
        </span>
        <span className="text-end">Payment Mode:</span>
        {/* <span className="text-end">(Cash)</span> */}
        <span className="text-end">({(props.paymentModeList && props.master.PaymentModeId)?props.paymentModeList.filter((list)=>list.id==props.master.PaymentModeId)[0].name:""})</span>
        <span className="text-end">Paid Amount:</span>
        <span className="text-end">{parseFloat(props.master.PaidAmount?props.master.PaidAmount:0).toFixed(2)}</span>
        <span className="text-end">Return Amount:</span>
        <span className="text-end">{parseFloat(props.master.ReturnAmount?props.master.ReturnAmount:0).toFixed(2)}</span>
      </div>
      <div class="invoicePrintFooter">
        <p>
          বিঃ দ্রঃ পাতা কাটা ঔষধ, ইন্সুলিন, ভ্যকসিন ও সুগার টেস্ট স্ট্রিপ ফেরত
          বা পরিবর্তন করা হয় না ।
        </p>
      </div>
    </div>
  );
});

export { SalesReturnToPrint };
