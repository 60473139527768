import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportSalesTransaction = (props) => {
  const serverpage = "reportsalestransaction"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [userList, setUserList] = useState(null);
  const [paymentModeList, setPaymentModeList] = useState(null);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedSalesById, setSelectedSalesById] = useState(0);
  const [selectedPaymentModeId, setSelectedPaymentModeId] = useState(0);
  const [SubHeader, setSubHeader] = useState({
    NetAmount: 0
  });

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const PrintPDFExcelExportFunction = (reportType) => {
    const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
    let finalUrl = EXCEL_EXPORT_URL + "report/sales_transaction_report_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        serverpage +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&ServiceBy=" +
        selectedSalesById +
        "&PaymentModeId=" +
        selectedPaymentModeId +
        "&SubHeader=" +
        JSON.stringify(SubHeader) +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      // TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
      ServiceBy: selectedSalesById,
      PaymentModeId: selectedPaymentModeId,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    getUserList();
    getPaymentModeList();
    getDataList(); //invoice list
    setBFirst(false);
  }

  function getUserList() {
    let params = {
      action: "UserList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setUserList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  
  function getPaymentModeList() {
    let params = {
      action: "PaymentModeList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setPaymentModeList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Invoice date",
      width: "12%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SalesPerson",
      label: "Sales Person",
      align: "left",
      // width: "10%",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "PaymentMode",
      label: "Pay. Mode",
      align: "left",
      width: "15%",
      visible: true,
      sort: true,
      filter: true,
    },
        {
      field: "NetPaymentAmount",
      label: "Amount",
      width: "15%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    // {
    //   field: "InvoiceNo",
    //   label: "Invoice",
    //   width: "10%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    // {
    //   field: "SubTotalAmount",
    //   label: "Inv Price",
    //   width: "8%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    // {
    //   field: "DiscAmt",
    //   label: "Disc Amt (-)",
    //   width: "8%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },

    // {
    //   field: "VatAmount",
    //   label: "VAT (+)",
    //   width: "8%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    // {
    //   field: "VoidAmount",
    //   label: "Void Amt (-)",
    //   width: "10%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },



    // {
    //   field: "TotalCost",
    //   label: "Total Cost",
    //   width: "10%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },

    // {
    //   field: "Profit",
    //   label: "Profit",
    //   width: "5%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    // {
    //   field: "GPPercentage",
    //   label: "GP%",
    //   width: "5%",
    //   align: "right",
    //   type: "number",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
   
  ];

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const handleChangeFilterDropDown = (name, value) => {
    if (name == "ServiceBy") {
      setSelectedSalesById(value);
    } else if (name == "PaymentModeId") {
      setSelectedPaymentModeId(value);
    }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate, selectedSalesById, selectedPaymentModeId]);

  // const [SubHeader, setSubHeader] = useState({InvPrice:0,DiscAmt:0,VatAmt:0,VoidAmt:0,NetAmount:0,TotalCost:0,Profit:0});
  useEffect(() => {
    let NetAmount = 0;

    dataList.forEach((row) => {
      NetAmount += row.NetPaymentAmount;
    });

    NetAmount = new Intl.NumberFormat(["eng", "id"]).format(NetAmount);

    setSubHeader({
      NetAmount: NetAmount
    });
  }, [dataList]);

  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Sales Transaction
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        <div class="searchAdd">
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <div>
            <label class="pl-10">Sales Person</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ServiceBy"
              name="ServiceBy"
              autoComplete
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ServiceBy",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          <div>
            <label class="pl-10">Payment Mode</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="PaymentModeId"
              name="PaymentModeId"
              autoComplete
              options={paymentModeList ? paymentModeList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "PaymentModeId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          {/* <Button disabled={permissionType} label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button
            label={"Export"}
            class={"btnPrint"}
            onClick={PrintPDFExcelExportFunction}
          />
        </div>

        <div class="rpt-subheader searchAdd">
        
          <label class="pl-10">
            Total Amount: <span class="font-bold">{SubHeader.NetAmount}</span>
          </label>

        </div>

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={masterColumnList}
              rows={dataList ? dataList : {}}
              // actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSalesTransaction;
