import React, { forwardRef, useRef } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit } from "@material-ui/icons";
import {Button}  from "../../../components/CustomControl/Button";

import CustomTable from "components/CustomTable/CustomTable";
import { apiCall, apiOption , LoginUserInfo, language} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

// import UserEntryAddEditModal from "./UserEntryAddEditModal";

const MyProfile = (props) => {
  console.log('props: ', props);
  const serverpage = "myprofile"; // this is .php server page

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);



  // console.log("props modal: ", props);
  // const serverpage = "userentry"; // this is .php server page
  const [currentRow, setCurrentRow] = useState({
    id: "",
    UserName: "",
    LoginName: "",
    Password: "",
    RoleName: "",
    Email: "",
    DesignationId: "",
    confirmPassword: "",
    // IsActive: false,
  });
  const [errorObject, setErrorObject] = useState({});
  const UserInfo = LoginUserInfo();
 

  const [DesignationList, setDesignationList] = useState(null);
  const [currDesignationId, setCurrDesignationId] = useState(null);

  // const [RoleList, setRoleList] = useState(null);
  // const [currRoleId, setCurrRoleId] = useState(null);




  // const [currentRow, setCurrentRow] = useState([]);
  // const [showModal, setShowModal] = useState(false); //true=show modal, false=hide modal

  const {isLoading, data: dataList, error, ExecuteQuery} = ExecuteQueryHook(); //Fetch data
  // const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  // const PrintPDFExcelExportFunction = (reportType) => {
  //   let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  //   window.open(
  //     finalUrl +
  //       "?action=StrengthExport" +
  //       "&reportType=" +
  //       reportType +
  //       "&TimeStamp=" +
  //       Date.now()
  //   );
  // };
  /* =====End of Excel Export Code==== */


  
  React.useEffect(() => {
    getDesignation();
    // getDesignation(currentRow.DesignationId);
    // getRole(currentRow.RoleId);

  }, []);

  
  React.useEffect(() => {
    if(dataList.length>0){
      console.log('useEffect dataList: ', dataList);
      setCurrentRow(dataList[0]);

      setCurrDesignationId(dataList[0].DesignationId);
    }
  }, [dataList]);

  function getDesignation() {
    let params = {
      action: "DesignationList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setDesignationList(
        [{ id: "", name: "Select Designation" }].concat(res.data.datalist)
      );

      // setCurrDesignationId(selectDesignationId);
    });
  }

  
  // function getRole(selectRoleId) {
  //   let params = {
  //     action: "RoleList",
  //     lan: language(),
  //     UserId: UserInfo.UserId,
  //     ClientId: UserInfo.ClientId,
  //     BranchId: UserInfo.BranchId,
  //   };

  //   apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
  //     setRoleList(
  //       [{ id: "", name: "Select Role" }].concat(res.data.datalist)
  //     );

  //     setCurrRoleId(selectRoleId);
  //   });
  // }
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...currentRow };
    data[name] = value;

    setCurrentRow(data);

    setErrorObject({ ...errorObject, [name]: null });

    if (name === "DesignationId") {
      setCurrDesignationId(value);
    }

    // if (name === "RoleId") {
    //   setCurrRoleId(value);
    // }
  };

  function handleChangeCheck(e) {
    // console.log('e.target.checked: ', e.target.checked);
    const { name, value } = e.target;

    let data = { ...currentRow };
    data[name] = e.target.checked;
    setCurrentRow(data);
    //  console.log('aaa data: ', data);
  }

  const validateForm = () => {
    console.log("--------", currentRow);

    // let validateFields = ["UserName", "DiscountAmount", "DiscountPercentage"]
    let validateFields = [];
    if (currentRow["id"]) {
      validateFields = ["UserName", "LoginName", "Email", "DesignationId"];
    } else {
      validateFields = [
        "UserName",
        "LoginName",
        "Password",
        "Email",
        "DesignationId",
      ];
    }

    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentRow[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }

      let InEdit = "";
      if (currentRow["id"]) {
        InEdit = currentRow["id"];
      } else {
        InEdit = "";
      }

      if (InEdit) {
        errorData["Password"] = "";
      }

      //-----start confirm change password-----
      let cpassword = "";
      let cconfirmChangePassword = "";

      if (currentRow["Password"]) {
        cpassword = currentRow["Password"].trim();
      } else {
        cpassword = "";
      }

      if (currentRow["confirmChangePassword"]) {
        cconfirmChangePassword = currentRow["confirmChangePassword"].trim();
      } else {
        cconfirmChangePassword = "";
      }

      if (cpassword !== "") {
        if (cconfirmChangePassword == "") {
          props.openNoticeModal({
            isOpen: true,
            msg: "Enter Confirm Password",
            msgtype: 0,
          });

          //errorData["confirmChangePassword"] = "Enter Confirm Password";
          isValid = false;
        } else if (cpassword != cconfirmChangePassword) {
          props.openNoticeModal({
            isOpen: true,
            msg: "Password did not match",
            msgtype: 0,
          });

          //errorData["confirmChangePassword"] = "Password did not match";
          isValid = false;
        } else {
          errorData["confirmChangePassword"] = "";
        }
      }

      //-----end confirm change password-----
    });
    setErrorObject(errorData);
    return isValid;
  };

  function addEditAPICall() {
    if (validateForm()) {
      let params = {
        action: "dataAddEdit",
        lan: language(),
        UserId: UserInfo.UserId,
        ClientId: UserInfo.ClientId,
        BranchId: UserInfo.BranchId,
        rowData: currentRow,
      };

      apiCall.post(serverpage, { params }, apiOption()).then((res) => {
        // console.log('res: ', res);

        props.openNoticeModal({
          isOpen: true,
          msg: res.data.message,
          msgtype: res.data.success,
        });

        // console.log('props modal: ', props);
       // if (res.data.success === 1) {
//          props.modalCallback("addedit");
      //  }
      });
    }
  }


 
  
  if (bFirst) {
    /**First time call for datalist */
    getDataList();

    setBFirst(false);
  }

  /**Get data for table list */
  function getDataList(){

   
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  /** Action from table row buttons*/
  // function actioncontrol(rowData) {
  //   return (
  //     <>
  //       <Edit
  //         className={"table-edit-icon"}
  //         onClick={() => {
  //           editData(rowData);
  //         }}
  //       />

  //       <DeleteOutline
  //         className={"table-delete-icon"}
  //         onClick={() => {
  //           deleteData(rowData);
  //         }}
  //       />
  //     </>
  //   );
  // }

  // const addData = () => {
  //   // console.log("rowData: ", rowData);
  //   // console.log("dataList: ", dataList);

  //   setCurrentRow({
  //           id: "",
  //           UserName: "",
  //           LoginName: "",
  //           Password: "",
  //           // DivisionId: "",
  //           // DistrictId: "",
  //           RoleId: "",
  //           Email: "",
  //           DesignationId: "",
  //           confirmPassword: "",
  //           IsActive: false,
  //         });
  //   openModal();
  // };

  // const editData = (rowData) => {
  //   // console.log("rowData: ", rowData);
  //   // console.log("dataList: ", dataList);

  //   setCurrentRow(rowData);
  //   openModal();
  // };

  
  // function openModal() {
  //   setShowModal(true); //true=modal show, false=modal hide
  // }

  // function modalCallback(response) {
  //   //response = close, addedit
  //   // console.log('response: ', response);
  //   getDataList();
  //   setShowModal(false); //true=modal show, false=modal hide

  // }

  // const deleteData = (rowData) => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this data!",
  //     icon: "warning",
  //     buttons: {
  //       confirm: {
  //         text: "Yes",
  //         value: true,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //       cancel: {
  //         text: "No",
  //         value: null,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //     },
  //     dangerMode: true,
  //   }).then((allowAction) => {
  //     if (allowAction) {
  //       deleteApi(rowData);
  //     }
  //   });
  // };

  // function deleteApi(rowData) {

        
 
  //   let params = {
  //     action: "deleteData",
  //     lan: language(),
  //     UserId: UserInfo.UserId,
  //     ClientId: UserInfo.ClientId,
  //     BranchId: UserInfo.BranchId,
  //     rowData: rowData,
  //   };

  //   // apiCall.post("productgroup", { params }, apiOption()).then((res) => {
  //   apiCall.post(serverpage, { params }, apiOption()).then((res) => {
  //     console.log('res: ', res);
  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: res.data.message,
  //       msgtype: res.data.success,
  //     });
  //     getDataList();
  //   });

  // }



  return (
    <>
      <div class="bodyContainer">
        {/* <!-- ######-----TOP HEADER-----####### --> */}
        <div class="topHeader">
          <h4>
            Home ❯ My Profile
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        {/* <div class="searchAdd">
          <label></label>
          <Button label={"ADD"} class={"btnAdd"} onClick={addData} />
        </div> */}

        <div class="subContainertwo">
          <div className="App">


          <div class="contactmodalBody pt-10">
            <label>User Name *</label>
            <input
              type="text"
              id="UserName"
              name="UserName"
              class={errorObject.UserName}
              placeholder="Enter User Name"
              value={currentRow.UserName}
              onChange={(e) => handleChange(e)}
            />

            <label>Login Name *</label>
            <input
              type="text"
              id="LoginName"
              name="LoginName"
              class={errorObject.LoginName}
              placeholder="Enter Login Name"
              value={currentRow.LoginName}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div class="contactmodalBody pt-10">
            <label>Password *</label>
            <input
              id="Password"
              name="Password"
              type="Password"
              class={errorObject.Password}
              placeholder="Enter Password"
              value={currentRow.Password}
              onChange={(e) => handleChange(e)}
            />

            <label>Confirm Password</label>
            <input
              id="confirmChangePassword"
              name="confirmChangePassword"
              type="Password"
              class={errorObject.confirmChangePassword}
              placeholder="Enter Confirm Password"
              value={currentRow.confirmChangePassword}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div class="contactmodalBody pt-10">

          <label>Email *</label>
            <input
              type="text"
              id="Email"
              name="Email"
              placeholder="Enter Email"
              class={errorObject.Email}
              value={currentRow.Email}
              onChange={(e) => handleChange(e)}
            ></input>

            <label>Role Name *</label>
            <input
              type="text"
              id="RoleName"
              name="RoleName"
              disabled={true}
              // placeholder="Enter Email"
              // class={errorObject.RoleName}
              value={currentRow.RoleName}
              // onChange={(e) => handleChange(e)}
            ></input>
            {/* <select
              id="RoleId"
              name="RoleId"
              class={errorObject.RoleId}
              value={currRoleId}
              onChange={(e) => handleChange(e)}
            >
              {RoleList &&
                RoleList.map((item, index) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
            </select> */}

  

          </div>

          <div class="contactmodalBody pt-10">

          <label>Designation *</label>
            <select
              id="DesignationId"
              name="DesignationId"
              class={errorObject.DesignationId}
              value={currDesignationId}
              onChange={(e) => handleChange(e)}
            >
              {DesignationList &&
                DesignationList.map((item, index) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
            </select>


            {/* <label> Is Active?</label>
            <input
              id="IsActive"
              name="IsActive"
              type="checkbox"
              checked={currentRow.IsActive}
              onChange={handleChangeCheck}
            /> */}
          </div>

          <div class="modalItem">
            {/* {props.currentRow.id && ( */}
              <Button
                label={"Update"}
                class={"btnUpdate"}
                onClick={addEditAPICall}
              />
            {/* // )}
            // {!props.currentRow.id && (
            //   <Button
            //     label={"Save"}
            //     class={"btnSave"}
            //     onClick={addEditAPICall}
            //   />
            // )} */}
          </div>



            {/* <CustomTable
              columns={columnList}
              rows={dataList?dataList:{}}
              actioncontrol={actioncontrol}
            /> */}
          </div>
        </div>
      </div>
      {/* <!-- BODY CONTAINER END --> */}


      {/* {showModal && (<UserEntryAddEditModal masterProps={props} currentRow={currentRow} modalCallback={modalCallback}/>)} */}


    </>
  );
};

export default MyProfile;