import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

import { useReactToPrint } from "react-to-print";
import { SalesToPrint } from "./SalesToPrint";

const Sales = (props) => {
  const serverpage = "sales"; // this is .php server page
  const permissionType = props.permissionType;

  const componentRef = useRef();

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [listEditPanelToggle, setListEditPanelToggle] = useState(true); //when true then show list, when false then show add/edit
  // const [listEditPanelToggle, setListEditPanelToggle] = useState(false); //when true then show list, when false then show add/edit
  const [userList, setUserList] = useState(null);
  const [referenceList, setReferenceList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [paymentModeList, setPaymentModeList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [productVirtualList, setProductVirtualList] = useState(null);
  const [currentInvoice, setCurrentInvoice] = useState([]); //this is for master information. It will send to sever for save
  const [currentMany, setCurrentMany] = useState([]); //this is for many one record add/edit
  const [manyDataList, setManyDataList] = useState([]); //This is for many table. It will send to sever for save
  const [deletedItems, setDeletedItems] = useState([]); //Which products delete from many
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [currentDate, setCurrentDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  const [errorObjectMaster, setErrorObjectMaster] = useState({});
  const [errorObjectMany, setErrorObjectMany] = useState({});

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list
  const {
    isLoading: holdIsLoading,
    data: holdDataList,
    error: holdError,
    ExecuteQuery: HoldExecuteQuery,
  } = ExecuteQueryHook(); //Fetch data master list

  const {
    isLoading: isLoadingSingle,
    data: dataListSingle,
    error: errorSingle,
    ExecuteQuery: ExecuteQuerySingle,
  } = ExecuteQueryHook(); //Fetch data for single

  const UserInfo = LoginUserInfo();
  console.log("UserInfo: ", UserInfo);

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
        "?action=SalesExport" +
        "&reportType=excel" +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&TimeStamp=" +
        Date.now()
    );

    // let finalUrl = EXCEL_EXPORT_URL + "report/sales_report_excel.php";

    // window.open(
    //   finalUrl +"?menukey=" +serverpage
    //    +"&TransactionId=" + 24
    //    + "&TimeStamp=" + Date.now()
    // );
  };
  /* =====End of Excel Export Code==== */

  const newInvoice = () => {
    setManyDataList([]);
    setDeletedItems([]);

    let params = {
      action: "NextInvoiceNumber",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 2,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      console.log("UserInfo.UserId: ", UserInfo.UserId);

      setCurrentInvoice({
        id: "",
        TransactionTypeId: 2,
        TransactionDate: currentDate,
        InvoiceNo: res.data.datalist,
        UserId: +UserInfo.UserId,
        ServiceBy: +UserInfo.UserId,
        ReferenceId: "",
        CustomerId: "",
        BPosted: 0,

        SumSubTotalAmount: "",
        SumVatAmount: "",
        SumDiscountAmount: "",

        SumTotalAmount: "",
        SumCommissionPercentage: "",
        SumCommission: "",
        NetPaymentAmount: "",

        PaymentModeId: 1,
        PaymentReference: "",
        PaidAmount: "",
        ReturnAmount: "",
        IsPrintInvoice: false,
      });
    });

    resetMany();
  };

  function resetMany() {
    setCurrentMany({
      autoId: -1,
      TransactionItemsId: "",
      TransactionId: "",
      ProductId: "",
      ProductName: "Select product",
      ProductNamePrint: "",
      Quantity: "",
      MRP: "",
      SubTotalAmount: "",

      VatAmount: "",
      DiscountAmount: "",

      VatonTrade: "",
      DiscountPercentage: "",
      DiscountAmountPerUnit: "",
      NewCost: "",
      // Commission: "",
      TotalAmount: "",
    });
  }

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  /**Get data for table list */
  function getHoldDataList() {
    let params = {
      action: "getHoldDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 2,
    };
    // console.log('LoginUserInfo params: ', params);

    HoldExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    newInvoice();

    getUserList();
    getReferenceList();
    getCustomerList();
    getPaymentModeList();

    getProductList();
    getProductVirtualList();

    getDataList(); //invoice list

    setBFirst(false);
  }

  function addData() {
    newInvoice();
    getHoldDataList();

    setListEditPanelToggle(false); // false = hide list and show add/edit panel
  }

  function getUserList() {
    let params = {
      action: "UserList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setUserList(
        [{ id: "", name: "Select sales by" }].concat(res.data.datalist)
      );
    });
  }

  function getReferenceList() {
    let params = {
      action: "ReferenceList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setReferenceList(
        [{ id: "", name: "Select reference" }].concat(res.data.datalist)
      );
    });
  }

  function getCustomerList() {
    let params = {
      action: "CustomerList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setCustomerList(
        [{ id: "", name: "Select customer" }].concat(res.data.datalist)
      );
    });
  }

  function getPaymentModeList() {
    let params = {
      action: "PaymentModeList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setPaymentModeList(
        [{ id: "", name: "Select" }].concat(res.data.datalist)
      );
    });
  }

  function getProductList() {
    let params = {
      action: "ProductList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductList(
        [{ id: "", name: "Select product" }].concat(res.data.datalist)
      );
    });
  }

  function getProductVirtualList() {
    let params = {
      action: "ProductVirtualList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductVirtualList(["Select product"].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Date",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InvoiceNo",
      label: "Invoice No.",
      width: "15%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SalesByName",
      label: "Sales By",
      align: "left",
      // width: "30%",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "UserName",
      label: "Billing By",
      align: "left",
      // width: "30%",
      visible: true,
      sort: true,
      filter: true,
    },
    // {
    //   field: "ChallanNo",
    //   label: "Supplier Invoice No.",
    //   width: "10%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    {
      field: "NetPaymentAmount",
      label: "Net Payment",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "StatusName",
      label: "Status",
      width: "5%",
      align: "center",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "custom",
      label: "Action",
      width: "5%",
      align: "center",
      visible: true,
      // sort: false,
      // filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrolmaster(rowData) {
    return (
      <>
        {rowData.BPosted === 0 && permissionType === 0 && (
          <Edit
            className={"table-edit-icon"}
            onClick={() => {
              editData(rowData);
            }}
          />
        )}

        {rowData.BPosted === 0 && permissionType === 0 && (
          <DeleteOutline
            className={"table-delete-icon"}
            onClick={() => {
              deleteData(rowData);
            }}
          />
        )}

        {(rowData.BPosted === 1 || permissionType === 1) && (
          <ViewList
            className={"table-view-icon"}
            onClick={() => {
              viewData(rowData);
            }}
          />
        )}
      </>
    );
  }

  const editData = (rowData) => {
    getDataSingleFromServer(rowData.id);
  };

  const viewData = (rowData) => {
    getDataSingleFromServer(rowData.id);
  };

  const getDataSingleFromServer = (id) => {
    let params = {
      action: "getDataSingle",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      id: id,
    };

    setDeletedItems([]);

    ExecuteQuerySingle(serverpage, params);
    getHoldDataList();
    resetMany();

    setListEditPanelToggle(false); // false = hide list and show add/edit panel
  };

  const holdInvColumnList = [
    // { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },

    {
      field: "InvoiceNo",
      label: "Inv",
      width: "18%",
      align: "center",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "CustomerName",
      label: "Customer",
      align: "left",
      // width: "30%",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "NetPaymentAmount",
      label: "Net Pay",
      align: "right",
      type: "number",
      width: "20%",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "custom",
      label: " ",
      width: "5%",
      align: "center",
      visible: true,
      // sort: false,
      // filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrolhold(rowData) {
    return (
      <>
        {permissionType === 0 && (
          <Edit
            className={"table-edit-icon"}
            onClick={() => {
              editData(rowData);
            }}
          />
        )}
      </>
    );
  }

  useEffect(() => {
    console.log("dataListSingle: ", dataListSingle);

    if (dataListSingle.master) {
      // console.log("dataListSingle: ", dataListSingle.master[0]);
      if (permissionType === 1) {
        dataListSingle.master[0].BPosted = 1;
      }

      setCurrentInvoice(dataListSingle.master[0]);
    }
    if (dataListSingle.items) {
      setManyDataList(dataListSingle.items);
      // console.log('dataListSingle: ', dataListSingle.items[0]);
    }
  }, [dataListSingle]);

  const backToList = () => {
    setListEditPanelToggle(true); // true = show list and hide add/edit panel
    getDataList(); //invoice list
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printInvoice = () => {
    // console.log("Print not done yet.");
    //console.log("Print not done yet.", componentRef.current);
    handlePrint();
  };

  const SalesReturnInv = () => {
    // console.log("Not done yet.");
    props.history.push("salesreturn");
  };

  const deleteData = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        deleteApi(rowData);
      }
    });
  };

  function deleteApi(rowData) {
    let params = {
      action: "deleteData",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      rowData: rowData,
    };

    apiCall.post(serverpage, { params }, apiOption()).then((res) => {
      // console.log('res: ', res);
      props.openNoticeModal({
        isOpen: true,
        msg: res.data.message,
        msgtype: res.data.success,
      });
      getDataList();
    });
  }

  function handleChangeCheck(e) {
    // console.log('e.target.checked: ', e.target.checked);
    const { name, value } = e.target;

    let data = { ...currentInvoice };
    data[name] = e.target.checked;
    setCurrentInvoice(data);
    console.log("aaa data: ", data);
  }

  const handleChangeMaster = (e) => {
    const { name, value } = e.target;
    // console.log("value: ", value);
    // console.log("name: ", name);
    let data = { ...currentInvoice };
    data[name] = value;

    if (name === "SumCommission" || name === "SumCommissionPercentage") {
      let SumTotalAmount = parseFloat(
        ConvertNullToZero(data["SumTotalAmount"])
      );

      if (name === "SumCommission") {
        /**when type commistion amount then set BLANK of % */
        data["SumCommissionPercentage"] = "";
      } else {
        /**when type commision % */
        let SumCommissionPercentage = parseFloat(
          ConvertNullToZero(data["SumCommissionPercentage"])
        );

        data["SumCommission"] = (
          (SumTotalAmount * SumCommissionPercentage) /
          100
        ).toFixed(2);
      }

      let SumCommission = parseFloat(ConvertNullToZero(data["SumCommission"]));
      let NetPaymentAmount = (SumTotalAmount - SumCommission).toFixed(2);

      data["NetPaymentAmount"] = NetPaymentAmount;
    } else if (name === "PaidAmount") {
    }

    let NetPaymentAmount = parseFloat(
      ConvertNullToZero(data["NetPaymentAmount"])
    );
    let PaidAmount = parseFloat(ConvertNullToZero(data["PaidAmount"]));
    let ReturnAmount =
      PaidAmount == 0 ? 0 : (PaidAmount - NetPaymentAmount).toFixed(2);

    data["ReturnAmount"] = ReturnAmount;
    console.log("data: ", data);

    setCurrentInvoice(data);

    setErrorObjectMaster({ ...errorObjectMaster, [name]: null });
  };

  const handleChangeChoosenMaster = (name, value) => {
    let data = { ...currentInvoice };
    data[name] = value;
    setCurrentInvoice(data);

    setErrorObjectMaster({ ...errorObjectMaster, [name]: null });
  };

  const handleChangeMany = (e) => {
    const { name, value } = e.target;
    let data = { ...currentMany };
    data[name] = value;

    if (
      name === "Quantity" ||
      name === "MRP" ||
      name === "VatonTrade" ||
      name === "DiscountPercentage" ||
      name === "DiscountAmountPerUnit"
    ) {
      setCurrentMany(calculationManyFields(name, data));
    } else {
      setCurrentMany(data);
    }

    setErrorObjectMany({ ...errorObjectMany, [name]: null });
  };

  const handleChangeChoosenMany = (name, value) => {
    let data = { ...currentMany };
    data[name] = value;

    /**when select product then some value take from product metadata */
    if (name === "ProductId") {
      var selectedProduct = productList.filter((prod) => prod.id === value);

      // console.log('selectedProduct: ', selectedProduct);
      // console.log('selectedProduct TradePrice: ', selectedProduct[0].TradePrice);
      // console.log('selectedProduct MRP: ', selectedProduct[0].MRP);
      // console.log('selectedProduct VatonTrade: ', selectedProduct[0].VatonTrade);

      console.log("selectedProduct: ", selectedProduct);

      data["ProductName"] = selectedProduct[0].name;
      data["ProductNamePrint"] = selectedProduct[0].ProductName;
      data["MRP"] = selectedProduct[0].MRP ? selectedProduct[0].MRP : 0;

      data["DiscountPercentage"] = selectedProduct[0].SalesDiscountPercentage
        ? selectedProduct[0].SalesDiscountPercentage
        : "";

      data["DiscountAmountPerUnit"] = selectedProduct[0].SalesDiscountAmount
        ? selectedProduct[0].SalesDiscountAmount
        : "";

      data["VatonTrade"] = selectedProduct[0].VatonSales
        ? selectedProduct[0].VatonSales
        : "";

      let defaultQty = 1;
      data["Quantity"] = defaultQty;
      data["NewCost"] = selectedProduct[0].NewCost
        ? selectedProduct[0].NewCost
        : 0;

      // if(selectedProduct[0].SalesDiscountPercentage){
      //   data["DiscountAmount"] = ((defaultQty*selectedProduct[0].SalesDiscountPercentage)/100).toFixed(2);
      // }

      setCurrentMany(calculationManyFields(name, data));
    } else {
      setCurrentMany(data);
    }

    // console.log("data handleChangeChoosenMany: ", data);

    setErrorObjectMany({ ...errorObjectMany, [name]: null });
  };

  const calculationManyFields = (name, data) => {
    // console.log("data: ", data);

    /**when type subtotal then no need calculate subtotal */
    if (name !== "SubTotalAmount") {
      data["SubTotalAmount"] = parseFloat(
        parseFloat(ConvertNullToZero(data["MRP"])) *
          parseInt(ConvertNullToZero(data["Quantity"]))
      ).toFixed(2);
    }

    /**when type discount amount per unit then BLANK discount %. And cal total discount amount*/
    if (name === "DiscountAmountPerUnit") {
      data["DiscountPercentage"] = "";
    } else if (name === "DiscountPercentage") {
      /**when type discount % per unit then BLANK discount amount. And cal total discount amount*/
      data["DiscountAmountPerUnit"] = "";
    }

    /**Caculate total discount amount */
    if (
      data["DiscountAmountPerUnit"] !== "" &&
      data["DiscountAmountPerUnit"] !== null
    ) {
      data["DiscountAmount"] = parseFloat(
        parseFloat(ConvertNullToZero(data["DiscountAmountPerUnit"])) *
          parseFloat(ConvertNullToZero(data["Quantity"]))
      ).toFixed(2);
      // console.log('datasssssssssssss DiscountAmountPerUnit: ', data);
    } else if (
      data["DiscountPercentage"] !== "" &&
      data["DiscountPercentage"] !== null
    ) {
      data["DiscountAmount"] = parseFloat(
        (parseFloat(ConvertNullToZero(data["SubTotalAmount"])) *
          parseFloat(ConvertNullToZero(data["DiscountPercentage"]))) /
          100
      ).toFixed(2);
      // console.log('datasssssssssssss DiscountPercentage: ', data);
    } else {
      data["DiscountAmount"] = 0;
    }
    // console.log('datasssssssssssss: ', data);

    /**VatonTrade=vat on sales */
    // data["VatAmount"] = parseFloat(
    //   ((parseFloat(ConvertNullToZero(data["SubTotalAmount"])) -
    //     parseFloat(ConvertNullToZero(data["DiscountAmount"]))) *
    //     parseFloat(ConvertNullToZero(data["VatonTrade"]))) /
    //     100
    // ).toFixed(2);
    data["VatAmount"] = parseFloat(
      (parseFloat(ConvertNullToZero(data["SubTotalAmount"])) *
        parseFloat(ConvertNullToZero(data["VatonTrade"]))) /
        100
    ).toFixed(2);

    /**when type TotalAmount then no need calculate TotalAmount */
    // if (name !== "TotalAmount") {
    data["TotalAmount"] = parseFloat(
      parseFloat(ConvertNullToZero(data["SubTotalAmount"])) +
        parseFloat(ConvertNullToZero(data["VatAmount"])) -
        parseFloat(ConvertNullToZero(data["DiscountAmount"]))
    ).toFixed(2);
    // }

    /**when type NewCost then no need calculate NewCost */
    // if (name !== "NewCost") {
    //   data["NewCost"] = parseFloat(
    //     (data["TotalAmount"] === "" ? 0 : parseFloat(data["TotalAmount"])) /
    //       ((data["Quantity"] === "" ? 0 : parseFloat(data["Quantity"])) +
    //         (data["BonusQty"] === "" ? 0 : parseFloat(data["BonusQty"])))
    //   ).toFixed(2);
    // }

    // console.log("data calculationManyFields: ", data);

    return data;
  };

  const calculationMasterFields = (list) => {
    console.log("list: ", list);
    let SumSubTotalAmount = 0;
    let SumVatAmount = 0;
    let SumDiscountAmount = 0;
    // let SumCommission = 0;
    let SumTotalAmount = 0;

    list.forEach((row, i) => {
      console.log("row: ", row);
      SumSubTotalAmount += parseFloat(ConvertNullToZero(row.SubTotalAmount));
      SumVatAmount += parseFloat(ConvertNullToZero(row.VatAmount));
      SumDiscountAmount += parseFloat(ConvertNullToZero(row.DiscountAmount));
      SumTotalAmount += parseFloat(ConvertNullToZero(row.TotalAmount));
    });

    let data = { ...currentInvoice };
    data["SumSubTotalAmount"] = SumSubTotalAmount.toFixed(2);
    data["SumVatAmount"] = SumVatAmount.toFixed(2);
    data["SumDiscountAmount"] = SumDiscountAmount.toFixed(2);
    data["SumTotalAmount"] = SumTotalAmount.toFixed(2);

    // SumCommission =
    //   (data["SumCommission"] === "" || data["SumCommission"] === null) ? 0 : parseFloat(data["SumCommission"]);

    data["NetPaymentAmount"] = (
      parseFloat(ConvertNullToZero(data["SumTotalAmount"])) -
      parseFloat(ConvertNullToZero(data["SumCommission"]))
    ).toFixed(2);

    let NetPaymentAmount = parseFloat(
      ConvertNullToZero(data["NetPaymentAmount"])
    );
    let PaidAmount = parseFloat(ConvertNullToZero(data["PaidAmount"]));
    let ReturnAmount =
      PaidAmount == 0 ? 0 : (PaidAmount - NetPaymentAmount).toFixed(2);

    data["ReturnAmount"] = ReturnAmount;

    console.log("data: ", data);

    setCurrentInvoice(data);
  };

  const ConvertNullToZero = (val) => {
    let retValue = val === "" || val === null ? 0 : val;
    return retValue;
  };

  const addEditManyItem = () => {
    if (validateFormMany()) {
      console.log("currentMany: ", currentMany);
      // console.log("data.TransactionItemsId: ", currentMany.TransactionItemsId);
      console.log("manyDataList: ", manyDataList);

      // let isExist = 0;
      // manyDataList.forEach((row, i) => {
      //   if (currentMany.ProductId === row.ProductId && isExist === 0) {
      //     isExist = 1;
      //   }
      // });

      // if (isExist === 1) {
      //   props.openNoticeModal({
      //     isOpen: true,
      //     msg: "Already added this product",
      //     msgtype: 0,
      //   });

      //   return;
      // }

      let rows = [];

      // var selectedProduct = productList.filter(
      //   (prod) => prod.id === currentMany.ProductId
      // );
      // console.log('selectedProduct: ', selectedProduct);
      // console.log("oldManyDataList: ", manyDataList);

      let isExisting = 0;
      manyDataList.forEach((row, i) => {
        let newRow = {};

        newRow.autoId = row.autoId; //Just unique id for delete/update
        newRow.TransactionItemsId = row.TransactionItemsId;
        newRow.TransactionId = row.TransactionId;
        newRow.ProductId = row.ProductId;
        newRow.ProductName = row.ProductName;
        newRow.ProductNamePrint = row.ProductNamePrint;
        newRow.NewCost = row.NewCost;
        // console.log('currentMany.autoId: ', currentMany.autoId);

        if (
          currentMany.ProductId === row.ProductId &&
          currentMany.autoId === -1
        ) {
          /**When This item already exist and Add again*/
          isExisting = 1;
          console.log("From 1");

          newRow.Quantity =
            parseInt(row.Quantity) + parseInt(currentMany.Quantity);
          newRow.MRP = row.MRP;

          newRow.VatonTrade = row.VatonTrade;
          newRow.DiscountPercentage = row.DiscountPercentage;
          newRow.DiscountAmountPerUnit = row.DiscountAmountPerUnit;

          newRow.SubTotalAmount = 0;
          newRow.DiscountAmount = 0;

          newRow.VatAmount = 0;
          newRow.TotalAmount = 0;

          newRow.Commission = row.Commission;
          newRow.NetPaymentAmount = 0;

          // newRow.SubTotalAmount = currentMany.SubTotalAmount;
          // newRow.DiscountAmount = currentMany.DiscountAmount;

          // newRow.VatAmount = currentMany.VatAmount;
          // newRow.TotalAmount = currentMany.TotalAmount;

          // newRow.Commission = row.Commission;
          // newRow.NetPaymentAmount = currentMany.NetPaymentAmount;

          console.log("newRow 111111111111: ", newRow);

          let retRow = calculationManyFields("Quantity", newRow); //send newRow ref and update in this array
          console.log("retRow1111111111: ", retRow);
        } else if (
          currentMany.ProductId === row.ProductId &&
          currentMany.autoId > 0
        ) {
          /**When EDIT the many item*/
          isExisting = 1;
          console.log("From 2");

          newRow.Quantity = currentMany.Quantity;
          newRow.MRP = currentMany.MRP;

          newRow.VatonTrade = currentMany.VatonTrade;
          newRow.DiscountPercentage = currentMany.DiscountPercentage;
          newRow.DiscountAmountPerUnit = currentMany.DiscountAmountPerUnit;

          newRow.SubTotalAmount = currentMany.SubTotalAmount;
          newRow.DiscountAmount = currentMany.DiscountAmount;

          newRow.VatAmount = currentMany.VatAmount;
          newRow.TotalAmount = currentMany.TotalAmount;

          newRow.Commission = currentMany.Commission;
          newRow.NetPaymentAmount = currentMany.NetPaymentAmount;
        } else {
          console.log("From 3");

          newRow.Quantity = row.Quantity;
          newRow.MRP = row.MRP;
          newRow.VatonTrade = row.VatonTrade;
          newRow.DiscountPercentage = row.DiscountPercentage;
          newRow.DiscountAmountPerUnit = row.DiscountAmountPerUnit;
          newRow.SubTotalAmount = row.SubTotalAmount;
          newRow.DiscountAmount = row.DiscountAmount;
          newRow.VatAmount = row.VatAmount;
          newRow.TotalAmount = row.TotalAmount;
          newRow.Commission = row.Commission;
          newRow.NetPaymentAmount = row.NetPaymentAmount;
        }

        rows.push(newRow);
      });

      // console.log("currentMany: ", currentMany);

      if (isExisting === 0) {
        console.log("From 4");
        /**when This item not exist in many */
        let newRow = {};
        newRow.autoId = currentMany.ProductId + moment().milliseconds(); //Just unique id for delete/update
        newRow.TransactionItemsId = currentMany.TransactionItemsId;
        newRow.TransactionId = currentMany.TransactionId;
        newRow.ProductId = currentMany.ProductId;
        newRow.ProductName = currentMany.ProductName; //selectedProduct[0].name;
        newRow.ProductNamePrint = currentMany.ProductNamePrint;
        newRow.NewCost = currentMany.NewCost; //selectedProduct[0].name;
        newRow.Quantity = currentMany.Quantity;
        newRow.MRP = currentMany.MRP;

        newRow.VatonTrade = currentMany.VatonTrade;
        newRow.DiscountPercentage = currentMany.DiscountPercentage;
        newRow.DiscountAmountPerUnit = currentMany.DiscountAmountPerUnit;

        newRow.SubTotalAmount = currentMany.SubTotalAmount;
        newRow.DiscountAmount = currentMany.DiscountAmount;

        newRow.VatAmount = currentMany.VatAmount;
        newRow.TotalAmount = currentMany.TotalAmount;

        newRow.Commission = currentMany.Commission;
        newRow.NetPaymentAmount = currentMany.NetPaymentAmount;
        rows.push(newRow);
      }
      console.log("rows: ", rows);

      setManyDataList(rows);
      // console.log("manyDataList: ", rows);
      calculationMasterFields(rows);

      resetMany();
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter required fields.",
        msgtype: 0,
      });
    }
  };

  const validateFormMaster = () => {
    let validateFields = ["TransactionDate", "ServiceBy", "InvoiceNo"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentInvoice[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }
    });
    setErrorObjectMaster(errorData);
    return isValid;
  };

  const validateFormMany = () => {
    let validateFields = ["ProductId", "Quantity", "MRP"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentMany[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }
    });
    setErrorObjectMany(errorData);
    return isValid;
  };

  const manyColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "ProductName",
      label: "Product",
      // width: "10%",
      align: "left",
      visible: true,
      // sort: true,
      // filter: false,
    },
    {
      field: "Quantity",
      label: "Qty",
      width: "6%",
      align: "right",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "MRP",
      label: "MRP",
      width: "5%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "SubTotalAmount",
      label: "Sub Total",
      width: "8%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "DiscountAmount",
      label: "Dis(Amt)",
      width: "6%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "VatAmount",
      label: "VAT (Amt)",
      width: "8%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },

    {
      field: "TotalAmount",
      label: "Total",
      width: "8%",
      align: "right",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "custom",
      label: "Action",
      width: "8%",
      align: "center",
      visible: true,
      // sort: false,
      // filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrol(rowData) {
    return (
      <>
        {!currentInvoice.BPosted && (
          <Edit
            className={"table-edit-icon"}
            onClick={() => {
              editDataMany(rowData);
            }}
          />
        )}

        {!currentInvoice.BPosted && (
          <DeleteOutline
            className={"table-delete-icon"}
            onClick={() => {
              deleteDataMany(rowData);
            }}
          />
        )}
      </>
    );
  }

  const editDataMany = (rowData) => {
    console.log("rowData: ", rowData);
    setCurrentMany(rowData);
  };

  const deleteDataMany = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        deleteInvoiceItem(rowData);
      }
    });
  };

  function deleteInvoiceItem(rowData) {
    // console.log("manyDataList: ", manyDataList);
    // console.log("rowData for delete: ", rowData);

    let data = manyDataList.filter(function (obj) {
      return obj.autoId !== rowData.autoId;
    });

    setManyDataList(data);
    calculationMasterFields(data);

    let delItems = [...deletedItems];
    delItems.push(rowData);
    console.log("delItems: ", delItems);

    setDeletedItems(delItems);
  }

  /**Print & Submit */
  const postInvoice = () => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Do you really want to post the stock?",
    //   icon: "warning",
    //   buttons: {
    //     confirm: {
    //       text: "Yes",
    //       value: true,
    //       visible: true,
    //       className: "",
    //       closeModal: true,
    //     },
    //     cancel: {
    //       text: "No",
    //       value: null,
    //       visible: true,
    //       className: "",
    //       closeModal: true,
    //     },
    //   },
    //   dangerMode: true,
    // }).then((allowAction) => {
    //   if (allowAction) {

    let cInvoiceMaster = { ...currentInvoice };
    cInvoiceMaster["BPosted"] = 1;

    let params = {
      action: "dataAddEdit",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      InvoiceMaster: cInvoiceMaster,
      InvoiceItems: manyDataList,
      DeletedItems: deletedItems,
    };

    addEditAPICall(params);

    //   }else{

    //   }
    // });
  };

  /**Hold Inv */
  function saveData(p) {
    let params = {
      action: "dataAddEdit",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      InvoiceMaster: currentInvoice,
      InvoiceItems: manyDataList,
      DeletedItems: deletedItems,
    };

    addEditAPICall(params);
  }

  function addEditAPICall(params) {
    if (manyDataList.length === 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter products.",
        msgtype: 0,
      });
      return;
    }

    if (validateFormMaster()) {
      if (
        params.InvoiceMaster.CustomerId === "" &&
        params.InvoiceMaster.BPosted === 0
      ) {
        /**Hold invoice can not save without customer. Aleast need to select 'Other' */
        props.openNoticeModal({
          isOpen: true,
          msg: "Please select customer for hold invoice.",
          msgtype: 0,
        });
        return;
      }

      if (
        (params.InvoiceMaster.PaidAmount === "" ||
          params.InvoiceMaster.PaidAmount === 0) &&
        params.InvoiceMaster.BPosted === 1
      ) {
        /**Hold invoice can not save without customer. Aleast need to select 'Other' */
        props.openNoticeModal({
          isOpen: true,
          msg: "Please enter Paid Amount.",
          msgtype: 0,
        });
        return;
      }

      // printInvoice

      console.log("params: ", params);

      apiCall.post(serverpage, { params }, apiOption()).then((res) => {
        console.log("res: ", res);

        if (res.data.success === 1) {
          if (
            params.InvoiceMaster.BPosted == 1 &&
            !params.InvoiceMaster.IsPrintInvoice
          ) {
            printInvoice();
          }
          // console.log('currentInvoice: ', currentInvoice);
          // if(currentInvoice.id === ""){
          //   //New
          //   getDataSingleFromServer(res.data.id);
          // }else{
          //   //Edit
          //   getDataSingleFromServer(currentInvoice.id);
          // }

          /**when post then need to refresh product because in combo show current stock*/
          if (params.InvoiceMaster.BPosted == 1) {
            getProductList();
            getProductVirtualList();
          }

          newInvoice();
          // console.log("rubel params: ", params.InvoiceMaster.BPosted);

          /**When draft invoice save/post then need to reload holdinoice list */
          // if (params.InvoiceMaster.BPosted === 0) {
          getHoldDataList();
          // }
        } else if (res.data.id > 0) {
          // console.log("rubel params else: ", params.InvoiceMaster.BPosted);

          /**when save but can not post then need to refresh */
          if (currentInvoice.id === "") {
            //New
            getDataSingleFromServer(res.data.id);
          } else {
            //Edit
            getDataSingleFromServer(currentInvoice.id);
          }

          /**when post then need to refresh product because in combo show current stock*/
          if (params.InvoiceMaster.BPosted == 1) {
            getProductList();
            getProductVirtualList();
          }
        }

        props.openNoticeModal({
          isOpen: true,
          msg: res.data.message,
          msgtype: res.data.success,
        });

        /**after save refresh the master list */
        // getDataList(); //invoice list
        // getDataSingleFromServer(res.data.id);

        // console.log('props modal: ', props);
        // if (res.data.success === 1) {
        //   props.modalCallback("addedit");
        // }
      });
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter required fields.",
        msgtype: 0,
      });
    }
  }

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate]);

  return (
    <>
      <div class="bodyContainer non-printable">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Product ❯ Sales
          </h4>
        </div>

        {listEditPanelToggle && (
          <>
            {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
            <div class="searchAdd">
              {/* <input type="text" placeholder="Search Product Group"/> */}
              <label class="pl-10">Start Date</label>
              <div>
                <input
                  type="date"
                  id="StartDate"
                  name="StartDate"
                  value={StartDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>

              <label class="pl-10">End Date</label>
              <div>
                <input
                  type="date"
                  id="EndDate"
                  name="EndDate"
                  value={EndDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>

              {/* <Button label={"ADD"} class={"btnAdd"} onClick={addData} /> */}

              <Button
                label={"Export"}
                class={"btnPrint"}
                onClick={PrintPDFExcelExportFunction}
              />
              <Button
                disabled={permissionType}
                label={"ADD"}
                class={"btnAdd"}
                onClick={addData}
              />
            </div>

            {/* <!-- ####---Master invoice list---####s --> */}
            <div class="subContainer">
              <div className="App">
                <CustomTable
                  columns={masterColumnList}
                  rows={dataList ? dataList : {}}
                  actioncontrol={actioncontrolmaster}
                />
              </div>
            </div>
          </>
        )}

        {!listEditPanelToggle && (
          <>
            {/* <!-- Sales MASTER HEADER START--> */}
            <div class="subContainer salesMasterHeader">
              {/* <div> */}
              <label># Inv No.*</label>
              {/* </div> */}
              <div>
                <input
                  type="text"
                  id="InvoiceNo"
                  name="InvoiceNo"
                  disabled
                  class={errorObjectMaster.InvoiceNo}
                  value={currentInvoice.InvoiceNo || ""}
                  onChange={(e) => handleChangeMaster(e)}
                />
              </div>

              {/* <label for="">Sales By</label>
            <select>
                <option value="">Sales person Name</option>
            </select> */}
              {/* <div class="header-item" id="headerItem-2"> */}
              {/* <div> */}
              <label>Sales By*</label>
              {/* </div> */}
              {/* <div class="plusGroup"> */}
              <div class="">
                <Autocomplete
                  autoHighlight
                  disabled={currentInvoice.BPosted}
                  className="chosen_dropdown"
                  id="ServiceBy"
                  name="ServiceBy"
                  class={errorObjectMaster.ServiceBy}
                  autoComplete
                  options={userList ? userList : []}
                  getOptionLabel={(option) => option.name}
                  value={
                    userList
                      ? userList[
                          userList.findIndex(
                            (list) => list.id === currentInvoice.ServiceBy
                          )
                        ]
                      : null
                  }
                  onChange={(event, valueobj) =>
                    handleChangeChoosenMaster(
                      "ServiceBy",
                      valueobj ? valueobj.id : ""
                    )
                  }
                  renderOption={(option) => (
                    <Typography className="chosen_dropdown_font">
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />

                {/* <button class="btnPlus">+</button> */}
              </div>
              {/* </div> */}

              {/* <label for="">Ref. By</label>
            <select name="" id="">
                <option value="Ref Name">Ref Name</option>
            </select> */}
              <label>Ref. By</label>
              <div class="">
                <Autocomplete
                  autoHighlight
                  disabled={currentInvoice.BPosted}
                  className="chosen_dropdown"
                  id="ReferenceId"
                  name="ReferenceId"
                  // class={errorObjectMaster.ReferenceId}
                  autoComplete
                  options={referenceList ? referenceList : []}
                  getOptionLabel={(option) => option.name}
                  value={
                    referenceList
                      ? referenceList[
                          referenceList.findIndex(
                            (list) => list.id === currentInvoice.ReferenceId
                          )
                        ]
                      : null
                  }
                  onChange={(event, valueobj) =>
                    handleChangeChoosenMaster(
                      "ReferenceId",
                      valueobj ? valueobj.id : ""
                    )
                  }
                  renderOption={(option) => (
                    <Typography className="chosen_dropdown_font">
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </div>

              {/* 
            <label for="">Customer</label>
            <div class="plusGroup">
                <select id="" name="">
                    <option value="audi">Ref Name </option>
                </select>
                <button class="btnPlus">+</button>
            </div> */}

              <label>Customer</label>
              <div class="">
                <Autocomplete
                  autoHighlight
                  disabled={currentInvoice.BPosted}
                  className="chosen_dropdown"
                  id="CustomerId"
                  name="CustomerId"
                  class={errorObjectMaster.CustomerId}
                  autoComplete
                  options={customerList ? customerList : []}
                  getOptionLabel={(option) => option.name}
                  value={
                    customerList
                      ? customerList[
                          customerList.findIndex(
                            (list) => list.id === currentInvoice.CustomerId
                          )
                        ]
                      : null
                  }
                  onChange={(event, valueobj) =>
                    handleChangeChoosenMaster(
                      "CustomerId",
                      valueobj ? valueobj.id : ""
                    )
                  }
                  renderOption={(option) => (
                    <Typography className="chosen_dropdown_font">
                      {option.name}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </div>

              {/* <label for="">Date</label>
            <input type="date"/> */}
              <label>Date*</label>
              <div>
                {/* <input
                  type="date"
                  id="TransactionDate"
                  name="TransactionDate"
                  disabled={currentInvoice.BPosted}
                  class={errorObjectMaster.TransactionDate}
                  value={currentInvoice.TransactionDate || ""}
                  onChange={(e) => handleChangeMaster(e)}
                /> */}
                <input
                  type="text"
                  id="TransactionDate"
                  name="TransactionDate"
                  disabled
                  class={errorObjectMaster.TransactionDate}
                  value={currentInvoice.TransactionDate || ""}
                />
              </div>
            </div>

            {!currentInvoice.BPosted && (
              <div class="subContainer salesHeader">
                {/* <!-- SALES HEADER PART-1 --> */}
                <div class="salesHeader-part1">
                  {/* <label for="">Item/Barcode</label>
               <input type="text" placeholder="Item/Barcode"/> */}

                  {/* <div> */}
                  {/* <div> */}
                  <label>Product/Barcode*</label>
                  {/* </div> */}
                  <div>
                    {/* <Autocomplete
                      autoHighlight
                      className="chosen_dropdown"
                      id="ProductId"
                      name="ProductId"
                      autoComplete
                      class={errorObjectMany.ProductId}
                      options={productList ? productList : []}
                      getOptionLabel={(option) => option.name}
                      value={
                        productList
                          ? productList[
                              productList.findIndex(
                                (list) => list.id == currentMany.ProductId
                              )
                            ]
                          : null
                      }
                      onChange={(event, valueobj) =>
                        handleChangeChoosenMany(
                          "ProductId",
                          valueobj ? valueobj.id : ""
                        )
                      }
                      renderOption={(option) => (
                        <Typography className="chosen_dropdown_font">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    /> */}

                    <Virtualize
                      productList={productVirtualList}
                      handleChangeChoosenMany={handleChangeChoosenMany}
                      currentMany={currentMany}
                      errorObjectMany={errorObjectMany}
                    />
                  </div>
                  {/* </div> */}

                  {/* <label for="">Quantity</label>
               <input type="number"/> */}
                  <label>Qty*</label>
                  <div>
                    {/* <input type="number" /> */}
                    <input
                      type="number"
                      id="Quantity"
                      name="Quantity"
                      class={errorObjectMany.Quantity}
                      value={currentMany.Quantity}
                      onChange={(e) => handleChangeMany(e)}
                    />
                  </div>

                  {/* <label for="">Dis. (%) </label>
<input type="number"/> */}
                  {/* <div> */}
                  <label>Dis.(%)/Unit</label>
                  {/* </div> */}
                  <div>
                    {/* <input type="number" /> */}
                    <input
                      type="number"
                      id="DiscountPercentage"
                      name="DiscountPercentage"
                      // disabled
                      // class={errorObjectMany.DiscountPercentage}
                      value={currentMany.DiscountPercentage}
                      onChange={(e) => handleChangeMany(e)}
                    />
                  </div>

                  {/* <label for="">Dis. (Amt) </label>
<input type="number"/> */}
                  <label>Dis. (Amt)/Unit</label>
                  <div>
                    <input
                      type="number"
                      id="DiscountAmountPerUnit"
                      name="DiscountAmountPerUnit"
                      // disabled
                      // class={errorObjectMany.DiscountAmountPerUnit}
                      value={currentMany.DiscountAmountPerUnit}
                      onChange={(e) => handleChangeMany(e)}
                    />
                  </div>
                  {/* <label for="">MRP</label>
            <div>
                <input type="number" id="mrp"/>
                <label>Old Rate?</label>
                <input class="widthauto" type="checkbox" name=""/> 
            </div> */}
                  <label>MRP*</label>
                  <div>
                    <input
                      type="number"
                      id="MRP"
                      name="MRP"
                      disabled
                      class={errorObjectMany.MRP}
                      value={currentMany.MRP}
                      onChange={(e) => handleChangeMany(e)}
                    />
                  </div>

                  {/* <label for="">VAT (%) </label>
            <input type="number" id="vat"/> */}
                  <label>Vat(%)</label>
                  <div>
                    <input
                      type="number"
                      id="VatonTrade"
                      name="VatonTrade"
                      disabled
                      // class={errorObjectMany.VatonTrade}
                      value={currentMany.VatonTrade}
                      onChange={(e) => handleChangeMany(e)}
                    />
                  </div>
                </div>

                {/* <!-- SALES HEADER PART-2 --> */}
                {/* <div class="salesHeader-part2">
                </div> */}

                {/* <!-- SALES HEADER PART-3 --> */}
                {/* <div class="salesHeader-part3">
                </div> */}

                {/* <!-- SALES HEADER PART-2 --> */}
                <div class="salesHeader-part2">
                  {/* <button class="btnAdd">Add</button> */}
                  <Button
                    label={"Add"}
                    class={"btnAdd"}
                    onClick={addEditManyItem}
                  />
                </div>

                {/* <!-- SALES HEADER PART-3 --> */}
                <div class="salesHeader-part3">
                  {/* <label for="">Subtotal</label>
               <input type="number"/> */}

                  <label>Sub Total</label>
                  <input
                    type="number"
                    id="SubTotalAmount"
                    name="SubTotalAmount"
                    disabled
                    // class={errorObjectMany.SubTotalAmount}
                    value={currentMany.SubTotalAmount}
                    onChange={(e) => handleChangeMany(e)}
                  />

                  {/* <label for="">Dis.</label>
               <input type="number"/> */}
                  <label>Dis(Amt)</label>
                  <input
                    type="number"
                    id="DiscountAmountDisable"
                    name="DiscountAmountDisable"
                    disabled
                    // class={errorObjectMany.DiscountAmount}
                    value={currentMany.DiscountAmount}
                    // onChange={(e) => handleChangeMany(e)}
                  />

                  {/* <label for="">Vat</label>
               <input type="number"/> */}
                  <label>VAT(Amt)</label>
                  <input
                    type="number"
                    id="VatAmount"
                    name="VatAmount"
                    disabled
                    // class={errorObjectMany.VatAmount}
                    value={currentMany.VatAmount}
                    // onChange={(e) => handleChangeMany(e)}
                  />

                  {/* <label id="total" for="">Total</label>
               <input type="text"/> */}
                  <label>Total</label>
                  <input
                    type="number"
                    id="TotalAmount"
                    name="TotalAmount"
                    disabled
                    // class={errorObjectMany.TotalAmount}
                    value={currentMany.TotalAmount}
                    // onChange={(e) => handleChangeMany(e)}
                  />
                </div>
              </div>
            )}

            <div class="subContainer salesBody">
              {/* <!-- TABLE AREA --> */}
              <div class="salesDetail">
                <CustomTable
                  columns={manyColumnList}
                  rows={manyDataList ? manyDataList : {}}
                  actioncontrol={actioncontrol}
                  ispagination={false}
                />
                {/* <table class="tableGlobal">
        <tr>
            <th>SL.</th>
            <th>Barcode</th>
            <th>Item Detail</th>
            <th>Qnty</th>
            <th>Rtn_Qnty</th>
            <th>MRP</th>
            <th>Sub Total</th>
            <th>Dis.</th>
            <th>VAT</th>
            <th>Total</th>
            <th>Action</th>
        </tr>
        <tr>
            <td>1.</td>
            <td>1234567890</td>
            <td>RFL MOG- Medium</td>
            <td>1</td>
            <td>0</td>
            <td>100.00</td>
            <td>100.00</td>
            <td>10.00</td>
            <td>4.50</td>
            <td>94.50</td>
            <td>Edit,Delete</td>
        </tr>
    </table> */}

                <div class="salesBodyAction">
                  {/* <div class="invDiscount">
            <label for="">Discount(%)</label>
            <input type="number"/>
            <label for="">Discount(Amt.)</label>
            <input type="number"/>
        </div> */}

                  <div class="btnAction">
                    <Button
                      disabled={false}
                      label={"Back"}
                      class={"btnClose"}
                      onClick={backToList}
                    />

                    <Button
                      disabled={permissionType === 1}
                      label={"New"}
                      class={"btnExchange"}
                      onClick={newInvoice}
                    />

                    {/* <button class="btnHold">Hold Inv.</button> */}
                    <Button
                      disabled={currentInvoice.BPosted}
                      label={"Hold Inv."}
                      class={"btnHold"}
                      onClick={saveData}
                    />

                    {/* <button class="btnPrint">Re Print</button> */}
                    <Button
                      disabled={false}
                      label={"Re Print"}
                      class={"btnPrint"}
                      onClick={printInvoice}
                    />

                    {/* <button class="btnVoid">Sales Return</button> */}
                    <Button
                      disabled={permissionType === 1}
                      label={"Sales Return"}
                      class={"btnVoid"}
                      onClick={SalesReturnInv}
                    />

                    {/* <button class="btnExchange">Exchange</button> */}
                  </div>
                </div>
              </div>

              <div class="salesCalculation">
                <h4>Invoice Summary</h4>

                <div class="autoCalc">
                  {/* <label for="">Sub Total</label>
        <input type="number"/> */}
                  <label>Sub Total</label>
                  <input
                    type="number"
                    id="SumSubTotalAmount"
                    name="SumSubTotalAmount"
                    disabled
                    // class={errorObjectMaster.SumSubTotalAmount}
                    value={currentInvoice.SumSubTotalAmount || ""}
                  />

                  {/* <label for="">Total VAT</label>
        <input type="number"/> */}
                  <label>Total VAT</label>
                  <input
                    type="number"
                    id="SumVatAmount"
                    name="SumVatAmount"
                    disabled
                    // class={errorObjectMaster.SumVatAmount}
                    value={currentInvoice.SumVatAmount || ""}
                  />

                  {/* <label for="">Total Discount</label>
        <input type="number"/> */}
                  {/* <!-- GRID CHIELD-5 --> */}
                  <label>Total Discount</label>
                  <input
                    type="number"
                    id="SumDiscountAmount"
                    name="SumDiscountAmount"
                    disabled
                    // class={errorObjectMaster.SumDiscountAmount}
                    value={currentInvoice.SumDiscountAmount || ""}
                  />

                  {/* <label for="">Total</label>
        <input type="number"/> */}
                  <label>Total</label>
                  <input
                    type="number"
                    id="SumTotalAmount"
                    name="SumTotalAmount"
                    disabled
                    // class={errorObjectMaster.Remarks}
                    value={currentInvoice.SumTotalAmount || ""}
                  />

                  <label>Inv. Dis. (%)</label>
                  <input
                    type="number"
                    id="SumCommissionPercentage"
                    name="SumCommissionPercentage"
                    disabled={currentInvoice.BPosted}
                    value={currentInvoice.SumCommissionPercentage || ""}
                    onChange={(e) => handleChangeMaster(e)}
                  />

                  <label>Inv. Dis. (Amt)</label>
                  <input
                    type="number"
                    id="SumCommission"
                    name="SumCommission"
                    disabled={currentInvoice.BPosted}
                    value={currentInvoice.SumCommission || ""}
                    onChange={(e) => handleChangeMaster(e)}
                  />
                </div>

                {/* <h3>NET PAYMENT</h3>
    <input type="text" id="netPayment"/> */}

                <h3>NET PAYMENT</h3>
                <input
                  type="number"
                  id="NetPaymentAmount"
                  name="NetPaymentAmount"
                  class="netPayment"
                  disabled
                  // class={errorObjectMaster.Remarks}
                  value={currentInvoice.NetPaymentAmount || ""}
                  // onChange={(e) => handleChangeMaster(e)}
                />

                <div class="payment">
                  {/* <label for="">Mode of Payment</label>
        <select name="" id=""></select>
         */}

                  <label>Payment Mode</label>
                  {/* <div class=""> */}
                  <Autocomplete
                    autoHighlight
                    disabled={currentInvoice.BPosted}
                    className="chosen_dropdown"
                    id="PaymentModeId"
                    name="PaymentModeId"
                    // class={errorObjectMaster.PaymentModeId}
                    autoComplete
                    options={paymentModeList ? paymentModeList : []}
                    getOptionLabel={(option) => option.name}
                    value={
                      paymentModeList
                        ? paymentModeList[
                            paymentModeList.findIndex(
                              (list) => list.id === currentInvoice.PaymentModeId
                            )
                          ]
                        : null
                    }
                    onChange={(event, valueobj) =>
                      handleChangeChoosenMaster(
                        "PaymentModeId",
                        valueobj ? valueobj.id : ""
                      )
                    }
                    renderOption={(option) => (
                      <Typography className="chosen_dropdown_font">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                  />
                  {/* </div> */}

                  <label>Payment Ref.</label>
                  <div>
                    <input
                      type="text"
                      id="PaymentReference"
                      name="PaymentReference"
                      disabled={currentInvoice.BPosted}
                      // class={errorObject.PaymentReference}
                      value={currentInvoice.PaymentReference}
                      onChange={(e) => handleChangeMaster(e)}
                    />
                  </div>

                  {/*         
        <label for="">Paid Amount</label>
        <input type="number"/>
        <label for="">Return Amount</label>
        <input type="number"/> */}

                  <label>Paid Amount</label>
                  <div>
                    <input
                      type="number"
                      id="PaidAmount"
                      name="PaidAmount"
                      disabled={currentInvoice.BPosted}
                      // class={errorObject.PaidAmount}
                      value={currentInvoice.PaidAmount}
                      onChange={(e) => handleChangeMaster(e)}
                    />
                  </div>

                  <label>Return Amount</label>
                  <div>
                    <input
                      type="number"
                      id="ReturnAmount"
                      name="ReturnAmount"
                      disabled
                      // class={errorObject.ReturnAmount}
                      value={currentInvoice.ReturnAmount}
                      // onChange={(e) => handleChangeMaster(e)}
                    />
                  </div>
                </div>
                <div class="mt-5">
                  <label for="">Don't Print Invoice</label>
                  <input
                    class="widthauto"
                    type="checkbox"
                    name="IsPrintInvoice"
                    id="IsPrintInvoice"
                    checked={currentInvoice.IsPrintInvoice}
                    onChange={handleChangeCheck}
                  />
                </div>

                {/* <button class="btnSales">Print & Submit</button> */}
                <Button
                  disabled={currentInvoice.BPosted}
                  label={"Print & Submit"}
                  class={"btnSales"}
                  onClick={postInvoice}
                />
              </div>

              <div class="holdDetail">
                <div className="App">
                  <CustomTable
                    columns={holdInvColumnList}
                    // rows={dataList ? dataList : {}}
                    rows={holdDataList ? holdDataList : {}}
                    actioncontrol={actioncontrolhold}
                    ispagination={false}
                  />
                </div>

                {/* <table class="tableGlobal">
              <tr>
                  <td>Inv No</td>
                  <td>Holded Inv.</td>
                  <td></td>

              </tr>
              <tr>
                  <td>1012.</td>
                  <td>Mr. Jalal Uddin</td>
                  <td>i</td>

              </tr>
              <tr>
                  <td>212</td>
                  <td>Mr. Nure Alam</td>
                  <td>i</td>

              </tr>
              <tr>
                  <td>332</td>
                  <td>Mr. Sajni Akter</td>
                  <td>i</td>

              </tr>
              <tr>
                  <td>435</td>
                  <td>Baby doll</td>
                  <td>i</td>
              </tr>
          </table> */}
              </div>
            </div>
          </>
        )}
      </div>
      <SalesToPrint
        ref={componentRef}
        master={currentInvoice}
        many={manyDataList}
        userList={userList}
        UserInfo={UserInfo}
        paymentModeList={paymentModeList}
      />
    </>
  );
};

export default Sales;
