import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportTrendAnalysis = (props) => {
  const serverpage = "reporttrendanalysis"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  // const [userList, setUserList] = useState(null);
  const [productGroupList, setProductGroupList] = useState(null);
  const [productCagegoryList, setProductCagegoryList] = useState(null);
  const [productGenericList, setProductGenericList] = useState(null);

  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedProductGroupId, setSelectedProductGroupId] = useState(0);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(0);
  const [selectedProductGenericId, setSelectedProductGenericId] = useState(0);

  // const [SubHeader, setSubHeader] = useState({
  //   InvPrice: 0,
  //   DiscAmt: 0,
  //   VatAmt: 0,
  //   VoidAmt: 0,
  //   NetAmount: 0,
  //   TotalCost: 0,
  //   Profit: 0,
  // });

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const PrintPDFExcelExportFunction = (reportType) => {
    const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
    let finalUrl = EXCEL_EXPORT_URL + "report/trendanalysis_report_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        serverpage +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&ProductGroupId=" +
        selectedProductGroupId +
        "&ProductCategoryId=" +
        selectedProductCategoryId +
        "&ProductGenericId=" +
        selectedProductGenericId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      // TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductGroupId: selectedProductGroupId,
      ProductCategoryId: selectedProductCategoryId,
      ProductGenericId: selectedProductGenericId,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    // getUserList();

    getProductGroup();
    getProductCategory(0);
    getProductGeneric(0);

    getDataList(); //invoice list
    setBFirst(false);
  }

  function getProductGroup() {
    let params = {
      action: "ProductGroupList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductGroupList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  function getProductCategory(pProductGroupId) {
    let params = {
      action: "ProductCategoryList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductGroupId: pProductGroupId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductCagegoryList(
        [{ id: 0, name: "All" }].concat(res.data.datalist)
      );
    });
  }

  function getProductGeneric(pProductGroupId) {
    let params = {
      action: "ProductGenericList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductGroupId: pProductGroupId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductGenericList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  // function getUserList() {
  //   let params = {
  //     action: "UserList",
  //     lan: language(),
  //     UserId: UserInfo.UserId,
  //     ClientId: UserInfo.ClientId,
  //     BranchId: UserInfo.BranchId,
  //   };

  //   apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
  //     setUserList([{ id: 0, name: "All" }].concat(res.data.datalist));
  //   });
  // }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "ProductBarcode",
      label: "Product Code",
      width: "7%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "ProductName",
      label: "Product Name",
      width: "20%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "GroupName",
      label: "Group",
      width: "6%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "CategoryName",
      label: "Category",
      width: "6%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "GenericName",
      label: "Generic",
      width: "19%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "RecQty",
      label: "Receive",
      width: "6%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "RecReturnQty",
      label: "Stock Return",
      width: "7%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SalesQty",
      label: "Sales",
      width: "6%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SalesReturnQty",
      label: "Sales Return",
      width: "6%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "AdjPlusQty",
      label: "Adj Plus",
      width: "7%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "AdjMinusQty",
      label: "Adj Minus",
      width: "7%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
  ];

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    // console.log("value: ", value);
    // console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const handleChangeFilterDropDown = (name, value) => {
    if (name == "ProductGroupId") {
      setSelectedProductGroupId(value);

      setSelectedProductCategoryId(0);
      setSelectedProductGenericId(0);
      getProductCategory(value);
      getProductGeneric(value);
    } else if (name == "ProductCategoryId") {
      setSelectedProductCategoryId(value);
    } else if (name == "ProductGenericId") {
      setSelectedProductGenericId(value);
    }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [
    StartDate,
    EndDate,
    selectedProductGroupId,
    selectedProductCategoryId,
    selectedProductGenericId,
  ]);

  // useEffect(() => {
  //   let InvPrice = 0,
  //     DiscAmt = 0,
  //     VatAmt = 0,
  //     VoidAmt = 0,
  //     NetAmount = 0,
  //     TotalCost = 0,
  //     Profit = 0;

  //   dataList.forEach((row) => {
  //     // console.log('row: ', row);
  //     InvPrice += row.SubTotalAmount;
  //     DiscAmt += row.DiscAmt;
  //     VatAmt += row.VatAmount;
  //     VoidAmt += row.VoidAmount;
  //     NetAmount += row.NetPaymentAmount;
  //     TotalCost += row.TotalCost;
  //     Profit += row.Profit;
  //   });

  //   InvPrice = new Intl.NumberFormat(["eng", "id"]).format(InvPrice);
  //   DiscAmt = new Intl.NumberFormat(["eng", "id"]).format(DiscAmt);
  //   VatAmt = new Intl.NumberFormat(["eng", "id"]).format(VatAmt);
  //   VoidAmt = new Intl.NumberFormat(["eng", "id"]).format(VoidAmt);
  //   NetAmount = new Intl.NumberFormat(["eng", "id"]).format(NetAmount);
  //   TotalCost = new Intl.NumberFormat(["eng", "id"]).format(TotalCost);
  //   Profit = new Intl.NumberFormat(["eng", "id"]).format(Profit);

  //   setSubHeader({
  //     InvPrice: InvPrice,
  //     DiscAmt: DiscAmt,
  //     VatAmt: VatAmt,
  //     VoidAmt: VoidAmt,
  //     NetAmount: NetAmount,
  //     TotalCost: TotalCost,
  //     Profit: Profit,
  //   });
  // }, [dataList]);

  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Trend Analysis Report
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        <div class="searchAdd">
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <div>
            <label class="pl-10">Product Group</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ProductGroupId"
              name="ProductGroupId"
              autoComplete
              options={productGroupList ? productGroupList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ProductGroupId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          <div>
            <label class="pl-10">Category</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ProductCategoryId"
              name="ProductCategoryId"
              autoComplete
              options={productCagegoryList ? productCagegoryList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ProductCategoryId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          <div>
            <label class="pl-10">Generic</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ProductGenericId"
              name="ProductGenericId"
              autoComplete
              options={productGenericList ? productGenericList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ProductGenericId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          {/* <Button disabled={permissionType} label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button
            label={"Export"}
            class={"btnPrint"}
            onClick={PrintPDFExcelExportFunction}
          />
        </div>
        {/*
        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
            Inv Price: <span class="font-bold">{SubHeader.InvPrice}</span>
          </label>
          <label class="pl-10">
            Disc Amt (-)️: <span class="font-bold">{SubHeader.DiscAmt}</span>
          </label>
          <label class="pl-10">
            VAT (+): <span class="font-bold">{SubHeader.VatAmt}</span>
          </label>
          <label class="pl-10">
            Void Amt (-): <span class="font-bold">{SubHeader.VoidAmt}</span>
          </label>
          <label class="pl-10">
            Net Amount: <span class="font-bold">{SubHeader.NetAmount}</span>
          </label>
          <label class="pl-10">
            Total Cost: <span class="font-bold">{SubHeader.TotalCost}</span>
          </label>
          <label class="pl-10">
            Profit: <span class="font-bold">{SubHeader.Profit}</span>
          </label>
        </div>
            */}

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={masterColumnList}
              rows={dataList ? dataList : {}}
              // actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportTrendAnalysis;
