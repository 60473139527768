import React, { forwardRef, useRef, useEffect } from "react";
import { ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

const StockStatus = (props) => {
  const serverpage = "stockstatus"; // this is .php server page

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [isListPanel, setIsListPanel] = useState(true); //when true then show list, when false then show ledger
  const [productGroupList, setProductGroupList] = useState(null);
  const [productCagegoryList, setProductCagegoryList] = useState(null);
  const [productGenericList, setProductGenericList] = useState(null);
  const [stockTypeList, setStockTypeList] = useState([{ id: 0, name: "All" },{ id: 1, name: "Non-Zero Stock" },{ id: 2, name: "Zero Stock" }]);

  const [currentRow, setcurrentRow] = useState([]);

  const [selectedProductGroupId, setSelectedProductGroupId] = useState(0);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(0);
  const [selectedProductGenericId, setSelectedProductGenericId] = useState(0);
  const [selectedStockTypeId, setSelectedStockTypeId] = useState(0);

  // const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

  const [StartDate, setStartDate] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [SubHeader, setSubHeader] = useState({
    TotalAmount: 0,
  });

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data
  const {
    isLoading: isLoadingMany,
    data: dataListMany,
    error: errorMany,
    ExecuteQuery: ExecuteQueryMany,
  } = ExecuteQueryHook(); //Fetch data

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let curGroupName = productGroupList.filter((obj)=> obj.id==selectedProductGroupId);
    let curCategoryName = productCagegoryList.filter((obj)=> obj.id==selectedProductCategoryId);
    let curGenericName = productGenericList.filter((obj)=> obj.id==selectedProductGenericId);
    let curStockTypeName = stockTypeList.filter((obj)=> obj.id==selectedStockTypeId);

    
    window.open(
      finalUrl +
        "?action=StockStatusReportExport" +
        "&reportType=excel" +
        "&ClientId=" + UserInfo.ClientId +
        "&BranchId=" + UserInfo.BranchId +
        "&ProductGroupId=" + selectedProductGroupId +
        "&ProductGroupName=Product Group: " + curGroupName[0].name +
        
        "&ProductCategoryId=" + selectedProductCategoryId +
        "&&ProductCategoryName=Category: " + curCategoryName[0].name +
        
        "&ProductGenericId=" + selectedProductGenericId +
        "&ProductGenerName=Generic: " + curGenericName[0].name +

        "&StockTypeId=" + selectedStockTypeId +
        "&StockTypeName=Stock Type: " + curStockTypeName[0].name +

        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleChangeFilterDropDown = (name, value) => {
    // setSelectedProductGroupId(value);
    // getDataList(value);


    if (name == "ProductGroupId") {
      setSelectedProductGroupId(value);

      setSelectedProductCategoryId(0);
      setSelectedProductGenericId(0);
      getProductCategory(value);
      getProductGeneric(value);
    } else if (name == "ProductCategoryId") {
      setSelectedProductCategoryId(value);
    } else if (name == "ProductGenericId") {
      setSelectedProductGenericId(value);
    } else if (name == "StockTypeId") {
      setSelectedStockTypeId(value);
    }
  };

  useEffect(() => {
    getDataList();
  }, [
    selectedProductGroupId,
    selectedProductCategoryId,
    selectedProductGenericId,
    selectedStockTypeId,
  ]);

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    // console.log("value: ", value);
    // console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const backToList = () => {
    setIsListPanel(true); // true = show list
  };

  useEffect(() => {
    console.log("useEffect call");

    getProductLedgerDataList();
    // if (dataListSingle.master) {
    //   console.log("dataListSingle: ", dataListSingle.master[0]);
    //   setCurrentInvoice(dataListSingle.master[0]);
    // }
    // if (dataListSingle.items) {
    //   setManyDataList(dataListSingle.items);
    //   // console.log('dataListSingle: ', dataListSingle.items[0]);
    // }
  }, [StartDate, EndDate, currentRow]);

  const columnList = [
    { field: "rownumber", label: "SL", align: "center", width: "4%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "GroupName",
      label: "Product Group",
      width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    // {
    //   field: "SystemBarcode",
    //   label: "System Barcode",
    //   // width: "10%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },

    {
      field: "ProductName",
      label: "Product",
      // width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "CategoryName",
      label: "Category",
      width: "7%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "GenericName",
      label: "Generic",
      // width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "ManufacturerName",
      label: "Manufacturer",
      width: "15%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    // {
    //   field: "ExpDate",
    //   label: "Exp Date",
    //   width: "6%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    {
      field: "Quantity",
      label: "Quantity",
      width: "8%",
      align: "right",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "NewCost",
      label: "Cost",
      width: "7%",
      align: "right",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "TotalAmount",
      label: "Total Amount",
      width: "10%",
      align: "right",
      visible: true,
      sort: true,
      filter: true,
      type:"number"
    },
    {
      field: "custom",
      label: "Ledger",
      width: "4%",
      align: "center",
      visible: true,
      sort: false,
      filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrolmaster(rowData) {
    return (
      <>
        <ViewList
          className={"table-view-icon"}
          onClick={() => {
            viewData(rowData);
          }}
        />
      </>
    );
  }

  const viewData = (rowData) => {
    console.log("rowData: ", rowData);
    
    setIsListPanel(false);//show item panel

    setcurrentRow(rowData);
    // getProductLedgerDataList(rowData.id);
  };

  /**Get single product data for table list */
  function getProductLedgerDataList() {
    console.log("currentRow: ", currentRow);

    let params = {
      action: "getProductLedgerDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductId: currentRow.id,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQueryMany(serverpage, params);
  }

  const columnListMany = [
    { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Date",
      width: "10%",
      align: "left",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "TransactionType",
      label: "Invoice Type",
      width: "10%",
      align: "left",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "InvoiceNo",
      label: "Invoice No",
      width: "10%",
      align: "left",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "QuantityIn",
      label: "Stock In",
      type: "number",
      // width: "10%",
      align: "right",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "QuantityOut",
      label: "Stock Out",
      type: "number",
      // width: "10%",
      align: "right",
      visible: true,
      sort: false,
      filter: false,
    },
    {
      field: "Balance",
      label: "Balance",
      type: "number",
      // width: "10%",
      align: "right",
      visible: true,
      sort: false,
      filter: false,
    },
    // {
    //   field: "ExpDate",
    //   label: "Expiry Date",
    //   width: "10%",
    //   align: "left",
    //   visible: true,
    //   sort: false,
    //   filter: false,
    // },
    // {
    //   field: "Remarks",
    //   label: "Remarks",
    //   // width: "7%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    // {
    //   field: "custom",
    //   label: "Ledger",
    //   width: "4%",
    //   align: "center",
    //   visible: true,
    //   sort: false,
    //   filter: false,
    // },
  ];

  if (bFirst) {
    /**First time call for datalist */
    getProductGroup();
    getProductCategory(0);
    getProductGeneric(0);

    getDataList();

    setBFirst(false);
  }


  function getProductGroup() {
    let params = {
      action: "ProductGroupList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductGroupList([{ id: 0, name: "All" }].concat(res.data.datalist));
      // getDataList(0);
    });
  }

  
  function getProductCategory(pProductGroupId) {
    let params = {
      action: "ProductCategoryList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductGroupId: pProductGroupId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductCagegoryList(
        [{ id: 0, name: "All" }].concat(res.data.datalist)
      );
    });
  }

  function getProductGeneric(pProductGroupId) {
    let params = {
      action: "ProductGenericList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductGroupId: pProductGroupId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductGenericList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }


  /**Get data for table list */
  function getDataList() {

    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      ProductGroupId: selectedProductGroupId,
      ProductCategoryId: selectedProductCategoryId,
      ProductGenericId: selectedProductGenericId,
      StockTypeId: selectedStockTypeId,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }



  
  useEffect(() => {
    let TotalAmount = 0;
    dataList.forEach((row) => {
      TotalAmount += row.TotalAmount;
    });
    // TotalAmount = new Intl.NumberFormat(["eng", "id"]).format(TotalAmount);
    setSubHeader({
      TotalAmount: TotalAmount
    });
  }, [dataList]);


  return (
    <>
      <div class="bodyContainer">
        {/* <!-- ######-----TOP HEADER-----####### --> */}
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Stock Status
          </h4>
        </div>


        {isListPanel && (<>
        <div class="searchAdd">

          {/* <div class="header-item"> */}

            <div>
              <label>Product Group</label>
            </div>
            <div class="">
              <Autocomplete
                autoHighlight
                disableClearable
                className="chosen_dropdown"
                id="ProductGroupId"
                name="ProductGroupId"
                autoComplete
                options={productGroupList ? productGroupList : []}
                getOptionLabel={(option) => option.name}
                defaultValue={{ id: 0, name: "All" }}
                onChange={(event, valueobj) =>
                  handleChangeFilterDropDown(
                    "ProductGroupId",
                    valueobj ? valueobj.id : ""
                  )
                }
                renderOption={(option) => (
                  <Typography className="chosen_dropdown_font">
                    {option.name}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </div>


            <div>
            <label class="pl-10">Category</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ProductCategoryId"
              name="ProductCategoryId"
              autoComplete
              options={productCagegoryList ? productCagegoryList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ProductCategoryId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          <div>
            <label class="pl-10">Generic</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="ProductGenericId"
              name="ProductGenericId"
              autoComplete
              options={productGenericList ? productGenericList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "ProductGenericId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>



          <div>
            <label class="pl-10">Stock Type</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="StockTypeId"
              name="StockTypeId"
              autoComplete
              options={stockTypeList ? stockTypeList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "StockTypeId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          {/* </div> */}

          {/* <Button label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button label={"Export"} class={"btnPrint"} onClick={PrintPDFExcelExportFunction} />

        </div>


        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
          Total Amount: <span class="font-bold">{SubHeader.TotalAmount?new Intl.NumberFormat(["eng", "id"]).format(SubHeader.TotalAmount.toFixed(2)):0}</span>
          </label>
        </div>


        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={columnList}
              rows={dataList ? dataList : {}}
              actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
        </>)}




        {!isListPanel && (<>
        <div class="searchAdd">
          <Button
            disabled={false}
            label={"Back"}
            class={"btnClose"}
            onClick={backToList}
          />
          
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>
          <label class="pl-10">Product Name: <span class="font-bold">{currentRow.ProductName}</span></label>
          <label class="pl-10">Manufacturer: <span class="font-bold">{currentRow.ManufacturerName}</span></label>
          <label class="pl-10">Stock Quantity: <span class="font-bold">{currentRow.Quantity}</span></label>
          <label class="pl-10">Cost: <span class="font-bold">{currentRow.NewCost}</span></label>
          <label class="pl-10">Total Amount: <span class="font-bold">{currentRow.TotalAmount?currentRow.TotalAmount.toFixed(2):0}</span></label>
        </div>

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={columnListMany}
              rows={dataListMany ? dataListMany : {}}
              actioncontrol={""}
              ispagination={true}
              paginationsize={20}
            />
          </div>
        </div>
        </>

          )}

      </div>
      {/* <!-- BODY CONTAINER END --> */}
    </>
  );
};

export default StockStatus;
