import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReceiveReturn = (props) => {
  const serverpage = "receivereturn"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [listEditPanelToggle, setListEditPanelToggle] = useState(true); //when true then show list, when false then show add/edit
  const [supplierList, setSupplierList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [productVirtualList, setProductVirtualList] = useState(null);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [currentInvoice, setCurrentInvoice] = useState([]); //this is for master information. It will send to sever for save
  const [currentMany, setCurrentMany] = useState([]); //this is for many one record add/edit
  const [manyDataList, setManyDataList] = useState([]); //This is for many table. It will send to sever for save
  const [deletedItems, setDeletedItems] = useState([]); //Which products delete from many

  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

  const [errorObjectMaster, setErrorObjectMaster] = useState({});
  const [errorObjectMany, setErrorObjectMany] = useState({});

  const baseUrl = process.env.REACT_APP_FRONT_URL;
  const [previewImage, setPreviewImage] = useState(
    `${baseUrl}image/receive/placeholder.png`
  );

  const [previewImages, setPreviewImages] = useState({
    ImageUrl: `${baseUrl}image/receive/placeholder.png`,
  });

  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const {
    isLoading: isLoadingSingle,
    data: dataListSingle,
    error: errorSingle,
    ExecuteQuery: ExecuteQuerySingle,
  } = ExecuteQueryHook(); //Fetch data for single

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
        "?action=ReceiveReturnExport" +
        "&reportType=excel" +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====Start of Excel Export Code==== */
  const printInvoice = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/receive_return_invoice_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        serverpage +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&TransactionId=" +
        currentInvoice.id +
        // "&EndDate=" +
        // EndDate +
        // "&ProductGroupId=" +
        // selectedProductGroupId +
        // "&SubHeader=" +
        // JSON.stringify(SubHeader) +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const newInvoice = () => {
    setManyDataList([]);
    setDeletedItems([]);

    let params = {
      action: "NextInvoiceNumber",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 5,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setCurrentInvoice({
        id: "",
        TransactionTypeId: 5,
        TransactionDate: currentDate,
        InvoiceNo: res.data.datalist,
        SupplierId: "",
        BPosted: 0,
        NetPaymentAmount: 0,
      });
    });

    resetMany();
  };

  function resetMany() {
    setCurrentMany({
      TransactionItemsId: "",
      TransactionId: "",
      ProductId: "",
      ProductName: "Select product",
      Quantity: "",
      BonusQty: "",
      TradePrice: "",
      VatonTrade: "",
      MRP: "",
      OrderQty: "",
      MfgDate: "",
      ExpDate: "",
      VatAmount: "",
      DiscountPercentage: "",
      DiscountAmount: "",
      SubTotalAmount: "",
      TotalAmount: "",
      Commission: "",
      // NetPaymentAmount:"",
      NewCost: "",
    });
  }

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      TransactionTypeId: 5,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    newInvoice();

    getSupplierList();
    getProductList();
    getProductVirtualList();

    getDataList(); //invoice list

    setBFirst(false);
  }

  function addData() {
    newInvoice();
    setListEditPanelToggle(false); // false = hide list and show add/edit panel
  }

  function getSupplierList() {
    let params = {
      action: "SupplierList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setSupplierList(
        [{ id: "", name: "Select supplier" }].concat(res.data.datalist)
      );
    });
  }

  function getProductList() {
    let params = {
      action: "ProductList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductList(
        [{ id: "", name: "Select product" }].concat(res.data.datalist)
      );
    });
  }

  function getProductVirtualList() {
    let params = {
      action: "ProductVirtualList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setProductVirtualList(["Select product"].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "5%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Date",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InvoiceNo",
      label: "Return Invoice No.",
      width: "15%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SupplierName",
      label: "Supplier",
      align: "left",
      // width: "30%",
      visible: true,
      sort: true,
      filter: true,
    },
    // {
    //   field: "ChallanNo",
    //   label: "Supplier Invoice No.",
    //   width: "10%",
    //   align: "left",
    //   visible: true,
    //   sort: true,
    //   filter: true,
    // },
    {
      field: "NetPaymentAmount",
      label: "Total",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "StatusName",
      label: "Status",
      width: "5%",
      align: "center",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "custom",
      label: "Action",
      width: "5%",
      align: "center",
      visible: true,
      // sort: false,
      // filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrolmaster(rowData) {
    return (
      <>
        {rowData.BPosted === 0 && permissionType === 0 && (
          <Edit
            className={"table-edit-icon"}
            onClick={() => {
              editData(rowData);
            }}
          />
        )}

        {rowData.BPosted === 0 && permissionType === 0 && (
          <DeleteOutline
            className={"table-delete-icon"}
            onClick={() => {
              deleteData(rowData);
            }}
          />
        )}

        {(rowData.BPosted === 1 || permissionType === 1) && (
          <ViewList
            className={"table-view-icon"}
            onClick={() => {
              viewData(rowData);
            }}
          />
        )}
      </>
    );
  }

  const editData = (rowData) => {
    getDataSingleFromServer(rowData.id);
  };

  const viewData = (rowData) => {
    getDataSingleFromServer(rowData.id);
  };

  const getDataSingleFromServer = (id) => {
    let params = {
      action: "getDataSingle",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      id: id,
    };

    // const [currentInvoice, setCurrentInvoice] = useState([]); //this is for master information. It will send to sever for save
    // const [currentMany, setCurrentMany] = useState([]); //this is for many one record add/edit
    // const [manyDataList, setManyDataList] = useState([]); //This is for many table. It will send to sever for save
    // const [deletedItems, setDeletedItems] = useState([]); //Which products delete from many
    setDeletedItems([]);

    ExecuteQuerySingle(serverpage, params);
    resetMany();

    setListEditPanelToggle(false); // false = hide list and show add/edit panel
  };

  useEffect(() => {
    console.log("dataListSingle: ", dataListSingle);

    if (dataListSingle.master) {
      // console.log('dataListSingle: ', dataListSingle.master[0]);

      if (permissionType === 1) {
        dataListSingle.master[0].BPosted = 1;
      }

      setCurrentInvoice(dataListSingle.master[0]);
    }
    if (dataListSingle.items) {
      setManyDataList(dataListSingle.items);
      // console.log('dataListSingle: ', dataListSingle.items[0]);
    }
  }, [dataListSingle]);

  const backToList = () => {
    setListEditPanelToggle(true); // true = show list and hide add/edit panel
    getDataList(); //invoice list
  };

  const deleteData = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        deleteApi(rowData);
      }
    });
  };

  function deleteApi(rowData) {
    let params = {
      action: "deleteData",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      rowData: rowData,
    };

    apiCall.post(serverpage, { params }, apiOption()).then((res) => {
      // console.log('res: ', res);
      props.openNoticeModal({
        isOpen: true,
        msg: res.data.message,
        msgtype: res.data.success,
      });
      getDataList();
    });
  }

  const handleChangeMaster = (e) => {
    // console.log('e: ', e);

    const { name, value } = e.target;
    // console.log('value: ', value);
    // console.log('name: ', name);
    let data = { ...currentInvoice };
    data[name] = value;

    // if (name === "SumCommission") {
    //   let SumTotalAmount =
    //     data["SumTotalAmount"] === "" ? 0 : parseFloat(data["SumTotalAmount"]);
    //   let SumCommission =
    //     data["SumCommission"] === "" ? 0 : parseFloat(data["SumCommission"]);
    //   let NetPaymentAmount = (SumTotalAmount - SumCommission).toFixed(0);

    //   data["NetPaymentAmount"] = NetPaymentAmount;

    //   calculateManyCommission(manyDataList, SumTotalAmount, SumCommission);
    // }
    setCurrentInvoice(data);

    setErrorObjectMaster({ ...errorObjectMaster, [name]: null });
  };

  const handleChangeChoosenMaster = (name, value) => {
    let data = { ...currentInvoice };
    data[name] = value;
    setCurrentInvoice(data);

    setErrorObjectMaster({ ...errorObjectMaster, [name]: null });
  };

  const handleChangeMany = (e) => {
    // console.log('e: ', e);

    const { name, value } = e.target;
    // console.log('value: ', value);
    // console.log('name: ', name);
    let data = { ...currentMany };
    data[name] = value;
    console.log("data: ", data);

    if (name === "TradePrice" || name === "Quantity") {
      setCurrentMany(calculationManyFields(name, data));
    } else {
      setCurrentMany(data);
    }

    setErrorObjectMany({ ...errorObjectMany, [name]: null });
  };

  const handleChangeChoosenMany = (name, value) => {
    console.log("name, value: ", name, value);
    let data = { ...currentMany };
    data[name] = value;

    /**when select product then some value take from product metadata */
    if (name === "ProductId") {
      // console.log("productList: ", productList);

      var selectedProduct = productList.filter((prod) => prod.id === value);
      console.log("selectedProduct: ", selectedProduct);

      // console.log('selectedProduct: ', selectedProduct);
      // console.log('selectedProduct TradePrice: ', selectedProduct[0].TradePrice);
      // console.log('selectedProduct MRP: ', selectedProduct[0].MRP);
      // console.log('selectedProduct VatonTrade: ', selectedProduct[0].VatonTrade);

      data["ProductName"] = selectedProduct[0].name;
      data["TradePrice"] = selectedProduct[0].TradePrice;
      // data["MRP"] = selectedProduct[0].MRP;
      // data["VatonTrade"] = selectedProduct[0].VatonTrade;

      setCurrentMany(calculationManyFields(name, data));
    } else {
      setCurrentMany(data);
    }

    // console.log("data handleChangeChoosenMany: ", data);

    setErrorObjectMany({ ...errorObjectMany, [name]: null });
  };

  const calculationManyFields = (name, data) => {
    /**when type Qty or price then calculate TotalAmount */
    data["TotalAmount"] = parseFloat(
      (data["TradePrice"] === "" ? 0 : parseFloat(data["TradePrice"])) *
        (data["Quantity"] === "" ? 0 : parseFloat(data["Quantity"]))
    ).toFixed(2);

    /**when type TradePrice then copy into NewCost */
    // if (name == "TradePrice") {
    data["NewCost"] = parseFloat(
      data["TradePrice"] === "" ? 0 : parseFloat(data["TradePrice"])
    ).toFixed(2);
    // }

    return data;
  };

  // const calculationMasterFields = (list) => {
  //   // console.log("list: ", list);
  //   let SumSubTotalAmount = 0;
  //   let SumVatAmount = 0;
  //   let SumDiscountAmount = 0;
  //   let SumCommission = 0;
  //   let SumTotalAmount = 0;

  //   list.forEach((row, i) => {
  //     console.log("row: ", row);
  //     SumSubTotalAmount +=
  //       row.SubTotalAmount === "" || row.SubTotalAmount === null
  //         ? 0
  //         : parseFloat(row.SubTotalAmount);
  //     SumVatAmount +=
  //       row.VatAmount === "" || row.VatAmount === null
  //         ? 0
  //         : parseFloat(row.VatAmount);
  //     SumDiscountAmount +=
  //       row.DiscountAmount === "" || row.DiscountAmount === null
  //         ? 0
  //         : parseFloat(row.DiscountAmount);
  //     SumTotalAmount +=
  //       row.TotalAmount === "" || row.TotalAmount === null
  //         ? 0
  //         : parseFloat(row.TotalAmount);
  //   });

  //   let data = { ...currentInvoice };
  //   data["SumSubTotalAmount"] = SumSubTotalAmount.toFixed(2);
  //   data["SumVatAmount"] = SumVatAmount.toFixed(2);
  //   data["SumDiscountAmount"] = SumDiscountAmount.toFixed(2);
  //   data["SumTotalAmount"] = SumTotalAmount.toFixed(0);

  //   SumCommission =
  //     data["SumCommission"] === "" || data["SumCommission"] === null
  //       ? 0
  //       : parseFloat(data["SumCommission"]);

  //   data["NetPaymentAmount"] = (
  //     (data["SumTotalAmount"] === "" || data["SumTotalAmount"] === null
  //       ? 0
  //       : parseFloat(data["SumTotalAmount"])) - SumCommission
  //   ).toFixed(0);
  //   console.log("data: ", data);

  //   setCurrentInvoice(data);
  //   calculateManyCommission(list, SumTotalAmount, SumCommission);
  // };

  // const calculateManyCommission = (
  //   mDataList,
  //   SumTotalAmount,
  //   SumCommission
  // ) => {
  //   /**calculate many commission and NetPaymentAmount  START*/
  //   let rows = [];
  //   mDataList.forEach((row, i) => {
  //     if (SumTotalAmount > 0) {
  //       row.Commission = (
  //         (row.TotalAmount * SumCommission) /
  //         SumTotalAmount
  //       ).toFixed(2);
  //     } else {
  //       row.Commission = 0;
  //     }

  //     row.NetPaymentAmount = (row.TotalAmount - row.Commission).toFixed(0);

  //     let Quantity = parseFloat(row.Quantity);
  //     // console.log("Quantity: ", row.Quantity);
  //     let BonusQty =
  //       row.BonusQty === "" || row.BonusQty === null
  //         ? 0
  //         : parseFloat(row.BonusQty);
  //     // console.log("BonusQty: ", BonusQty);
  //     let Qty = Quantity + BonusQty;
  //     // console.log("Qty: ", Qty);
  //     if (Qty > 0) {
  //       row.NewCost = (row.NetPaymentAmount / Qty).toFixed(2);
  //     } else {
  //       row.NewCost = 0;
  //     }

  //     rows.push(row);
  //   });
  //   setManyDataList(rows);
  //   /**calculate many commission and NetPaymentAmount  END*/
  // };

  const addEditManyItem = () => {
    if (validateFormMany()) {
      let isExist = 0;
      manyDataList.forEach((row, i) => {
        if (currentMany.ProductId === row.ProductId && isExist === 0) {
          isExist = 1;
        }
      });

      if (isExist === 1) {
        props.openNoticeModal({
          isOpen: true,
          msg: "Already added this product",
          msgtype: 0,
        });

        return;
      }

      let rows = [];
      var selectedProduct = productList.filter(
        (prod) => prod.id === currentMany.ProductId
      );

      // console.log('selectedProduct: ', selectedProduct);

      // console.log("oldManyDataList: ", manyDataList);

      let TotalNetPaymentAmount = 0;

      manyDataList.forEach((row, i) => {
        let newRow = {};

        newRow.autoId = row.autoId; //Just unique id for delete/update
        newRow.TransactionItemsId = row.TransactionItemsId;
        newRow.TransactionId = row.TransactionId;
        newRow.ProductId = row.ProductId;
        newRow.ProductName = row.ProductName;
        newRow.Quantity = row.Quantity;
        newRow.TradePrice = row.TradePrice;
        // newRow.MRP = row.MRP;
        // newRow.OrderQty = row.OrderQty;
        // newRow.BonusQty = row.BonusQty;
        // newRow.MfgDate = row.MfgDate;
        newRow.ExpDate = row.ExpDate;
        // newRow.VatonTrade = row.VatonTrade;
        // newRow.VatAmount = row.VatAmount;
        // newRow.DiscountPercentage = row.DiscountPercentage;
        // newRow.DiscountAmount = row.DiscountAmount;
        // newRow.SubTotalAmount = row.SubTotalAmount;
        newRow.TotalAmount = row.TotalAmount;
        // newRow.Commission = row.Commission;
        // newRow.NetPaymentAmount = row.NetPaymentAmount;
        newRow.NewCost = row.NewCost;

        TotalNetPaymentAmount += row.TotalAmount;

        rows.push(newRow);
      });

      let newRow = {};
      newRow.autoId = currentMany.ProductId + moment().milliseconds(); //Just unique id for delete/update
      newRow.TransactionItemsId = currentMany.TransactionItemsId;
      newRow.TransactionId = currentMany.TransactionId;
      newRow.ProductId = currentMany.ProductId;
      newRow.ProductName = selectedProduct[0].name;
      newRow.Quantity = currentMany.Quantity;
      // newRow.BonusQty = currentMany.BonusQty;
      newRow.TradePrice = currentMany.TradePrice;
      // newRow.VatonTrade = currentMany.VatonTrade;
      // newRow.MRP = currentMany.MRP;
      // newRow.OrderQty = currentMany.OrderQty;
      // newRow.MfgDate = currentMany.MfgDate;
      newRow.ExpDate = currentMany.ExpDate;
      // newRow.VatAmount = currentMany.VatAmount;
      // newRow.DiscountPercentage = currentMany.DiscountPercentage;
      // newRow.DiscountAmount = currentMany.DiscountAmount;
      // newRow.SubTotalAmount = currentMany.SubTotalAmount;
      newRow.TotalAmount = currentMany.TotalAmount;
      // newRow.Commission = currentMany.Commission;
      newRow.NetPaymentAmount = currentMany.TotalAmount;
      newRow.NewCost = currentMany.NewCost;
      TotalNetPaymentAmount += parseFloat(currentMany.TotalAmount);

      rows.push(newRow);

      setManyDataList(rows);

      let dataMaster = { ...currentInvoice };
      dataMaster["NetPaymentAmount"] = TotalNetPaymentAmount;
      setCurrentInvoice(dataMaster);

      resetMany();
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter required fields.",
        msgtype: 0,
      });
    }
  };

  const validateFormMaster = () => {
    let validateFields = ["TransactionDate", "SupplierId", "InvoiceNo"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentInvoice[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }
    });
    setErrorObjectMaster(errorData);
    return isValid;
  };

  // "ExpDate",

  const validateFormMany = () => {
    let validateFields = ["ProductId", "Quantity", "TotalAmount"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentMany[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }
    });
    setErrorObjectMany(errorData);
    return isValid;
  };

  const manyColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "ProductName",
      label: "Product",
      // width: "10%",
      align: "left",
      visible: true,
      // sort: true,
      // filter: false,
    },
    {
      field: "TradePrice",
      label: "Rtn Price",
      width: "5%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "Quantity",
      label: "Return Qnty",
      width: "6%",
      align: "right",
      visible: true,
      // sort: true,
      // filter: true,
    },

    {
      field: "TotalAmount",
      label: "Total",
      width: "4%",
      align: "right",
      type: "number",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "ExpDate",
      label: "Exp. Date",
      width: "7%",
      align: "left",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "NewCost",
      label: "Cost",
      width: "5%",
      align: "right",
      visible: true,
      // sort: true,
      // filter: true,
    },
    {
      field: "custom",
      label: "Action",
      width: "4%",
      align: "center",
      visible: true,
      // sort: false,
      // filter: false,
    },
  ];

  /** Action from table row buttons*/
  function actioncontrol(rowData) {
    return (
      <>
        {!currentInvoice.BPosted && (
          <DeleteOutline
            className={"table-delete-icon"}
            onClick={() => {
              deleteDataMany(rowData);
            }}
          />
        )}
      </>
    );
  }

  const deleteDataMany = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        deleteInvoiceItem(rowData);
      }
    });
  };

  function deleteInvoiceItem(rowData) {
    // console.log("manyDataList: ", manyDataList);
    // console.log("rowData for delete: ", rowData);

    let data = manyDataList.filter(function (obj) {
      return obj.autoId !== rowData.autoId;
    });

    setManyDataList(data);
    // calculationMasterFields(data);

    let delItems = [...deletedItems];
    delItems.push(rowData);
    // console.log("delItems: ", delItems);
    // console.log("rowData.TotalAmount: ", rowData.TotalAmount);

    setDeletedItems(delItems);

    let dataMaster = { ...currentInvoice };
    dataMaster["NetPaymentAmount"] =
      dataMaster["NetPaymentAmount"] - rowData.TotalAmount;
    setCurrentInvoice(dataMaster);
  }

  const postInvoice = () => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to post the stock?",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        let cInvoiceMaster = { ...currentInvoice };
        cInvoiceMaster["BPosted"] = 1;

        let params = {
          action: "dataAddEdit",
          lan: language(),
          UserId: UserInfo.UserId,
          ClientId: UserInfo.ClientId,
          BranchId: UserInfo.BranchId,
          InvoiceMaster: cInvoiceMaster,
          InvoiceItems: manyDataList,
          DeletedItems: deletedItems,
        };

        addEditAPICall(params);
      } else {
      }
    });
  };

  function saveData(p) {
    let params = {
      action: "dataAddEdit",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      InvoiceMaster: currentInvoice,
      InvoiceItems: manyDataList,
      DeletedItems: deletedItems,
    };

    addEditAPICall(params);
  }

  function addEditAPICall(params) {
    if (manyDataList.length === 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter products.",
        msgtype: 0,
      });
      return;
    }

    if (validateFormMaster()) {
      apiCall.post(serverpage, { params }, apiOption()).then((res) => {
        // console.log('res: ', res);

        if (res.data.success === 1) {
          // console.log('currentInvoice: ', currentInvoice);
          if (currentInvoice.id === "") {
            //New
            getDataSingleFromServer(res.data.id);
          } else {
            //Edit
            getDataSingleFromServer(currentInvoice.id);
          }

          /**when post then need to refresh product because in combo show current stock*/
          if (params.InvoiceMaster.BPosted == 1) {
            getProductList();
            getProductVirtualList();
          }
        }

        props.openNoticeModal({
          isOpen: true,
          msg: res.data.message,
          msgtype: res.data.success,
        });

        /**after save refresh the master list */
        // getDataList(); //invoice list
        // getDataSingleFromServer(res.data.id);

        // console.log('props modal: ', props);
        // if (res.data.success === 1) {
        //   props.modalCallback("addedit");
        // }
      });
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: "Please enter required fields.",
        msgtype: 0,
      });
    }
  }

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate]);

  const handleFileChange = (e, photoType) => {
    e.preventDefault(); // Prevent default form submission behavior
    const file = e.target.files[0];

    if (file) {
      uploadImage(file, photoType, e);
      setPreviewImages((prevImages) => ({
        ...prevImages,
        [photoType]: URL.createObjectURL(file),
      }));
    }
  };

  const uploadImage = (file, photoType) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    let timestamp = Math.floor(new Date().getTime() / 1000);

    formData.append("baseUrl", baseUrl);
    formData.append("formName", "receive");
    formData.append("file", file);
    formData.append("timestamp", timestamp);
    formData.append("filename", `./image/receive/${timestamp}_${file.name}`);

    apiCall
      .post("upload-image", formData, apiOption())
      .then((res) => {
        setCurrentInvoice((prevData) => ({
          ...prevData,
          [photoType]: `${timestamp}_${file.name}`,
        }));
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Product ❯ Stock Return
          </h4>
        </div>

        {listEditPanelToggle && (
          <>
            {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
            <div class="searchAdd">
              {/* <input type="text" placeholder="Search Product Group"/> */}
              {/* <label></label> */}
              <label class="pl-10">Start Date</label>
              <div>
                <input
                  type="date"
                  id="StartDate"
                  name="StartDate"
                  value={StartDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>

              <label class="pl-10">End Date</label>
              <div>
                <input
                  type="date"
                  id="EndDate"
                  name="EndDate"
                  value={EndDate}
                  onChange={(e) => handleChangeFilterDate(e)}
                />
              </div>
              {/* <Button label={"ADD"} class={"btnAdd"} onClick={addData} /> */}

              <Button
                label={"Export"}
                class={"btnPrint"}
                onClick={PrintPDFExcelExportFunction}
              />
              <Button
                disabled={permissionType}
                label={"ADD"}
                class={"btnAdd"}
                onClick={addData}
              />
            </div>

            {/* <!-- ####---Master invoice list---####s --> */}
            <div class="subContainer">
              <div className="App">
                <CustomTable
                  columns={masterColumnList}
                  rows={dataList ? dataList : {}}
                  actioncontrol={actioncontrolmaster}
                />
              </div>
            </div>
          </>
        )}

        {!listEditPanelToggle && (
          <>
            <div class="subContainer rcvHeader">
              {/* <!-- HEADER DIV GRID-ITEM-1 --> */}
              <div class="header-item" id="headerItem-1">
                <div>
                  <label>Date*</label>
                </div>
                <div>
                  <input
                    type="date"
                    id="TransactionDate"
                    name="TransactionDate"
                    disabled={currentInvoice.BPosted}
                    class={errorObjectMaster.TransactionDate}
                    value={currentInvoice.TransactionDate || ""}
                    onChange={(e) => handleChangeMaster(e)}
                  />
                </div>
              </div>

              {/* <!-- HEADER DIV GRID-ITEM-2 --> */}
              <div class="header-item" id="headerItem-2">
                <div>
                  <label>Supplier*</label>
                </div>
                {/* <div class="plusGroup"> */}
                <div class="">
                  <Autocomplete
                    autoHighlight
                    disabled={currentInvoice.BPosted}
                    className="chosen_dropdown"
                    id="SupplierId"
                    name="SupplierId"
                    class={errorObjectMaster.SupplierId}
                    autoComplete
                    options={supplierList ? supplierList : []}
                    getOptionLabel={(option) => option.name}
                    // value={selectedSupplier}
                    value={
                      supplierList
                        ? supplierList[
                            supplierList.findIndex(
                              (list) => list.id === currentInvoice.SupplierId
                            )
                          ]
                        : null
                    }
                    onChange={(event, valueobj) =>
                      handleChangeChoosenMaster(
                        "SupplierId",
                        valueobj ? valueobj.id : ""
                      )
                    }
                    renderOption={(option) => (
                      <Typography className="chosen_dropdown_font">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                  />

                  {/* <button class="btnPlus">+</button> */}
                </div>
              </div>

              {/* <!-- HEADER DIV GRID-ITEM-3 --> */}
              <div class="header-item" id="headerItem-3">
                <div>
                  <label>Return Invoice No.*</label>
                </div>
                <div>
                  <input
                    type="text"
                    id="InvoiceNo"
                    name="InvoiceNo"
                    disabled
                    class={errorObjectMaster.InvoiceNo}
                    value={currentInvoice.InvoiceNo || ""}
                    onChange={(e) => handleChangeMaster(e)}
                  />
                </div>
              </div>
            </div>

            {/* <!-- ##########-----PRODUCT RECEIVED INPUT AREA----############  --> */}
            {!currentInvoice.BPosted && (
              <div class="subContainer inputArea">
                {/* <!-- INPUR AREA PARTITION-1 INSERTION AREA --> */}
                <div class="input-areaPartition" id="areaPartion-1">
                  {/* <!--nth-child(1) = 1st GRID ITEM = 3rf--> */}
                  <div>
                    <div>
                      <label>Product Name*</label>
                    </div>

                    <div>
                      <Virtualize
                        productList={productVirtualList}
                        handleChangeChoosenMany={handleChangeChoosenMany}
                        currentMany={currentMany}
                        errorObjectMany={errorObjectMany}
                      />
                    </div>
                  </div>

                  {/* <!--nth-child(2) = 2nd GRID ITEM = 3rf--> */}
                  <div>
                    <div>
                      <label>Rtn Price</label>
                    </div>
                    <div>
                      {/* <input type="number" /> */}
                      <input
                        type="number"
                        id="TradePrice"
                        name="TradePrice"
                        // class={errorObject.TradePrice}
                        value={currentMany.TradePrice}
                        onChange={(e) => handleChangeMany(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>Return Qty*</label>
                    </div>
                    <div>
                      {/* <input type="number" /> */}
                      <input
                        type="number"
                        id="Quantity"
                        name="Quantity"
                        class={errorObjectMany.Quantity}
                        value={currentMany.Quantity}
                        onChange={(e) => handleChangeMany(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>Total*</label>
                    </div>
                    <div>
                      {/* <input type="number" /> */}
                      <input
                        type="number"
                        id="TotalAmount"
                        name="TotalAmount"
                        disabled
                        class={errorObjectMany.TotalAmount}
                        value={currentMany.TotalAmount}
                        onChange={(e) => handleChangeMany(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>Exp. Date</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        id="ExpDate"
                        name="ExpDate"
                        // class={errorObjectMany.ExpDate}
                        value={currentMany.ExpDate}
                        onChange={(e) => handleChangeMany(e)}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Cost</label>
                    </div>
                    <div>
                      {/* <input type="number" /> */}
                      <input
                        disabled
                        type="number"
                        id="NewCost"
                        name="NewCost"
                        // class={errorObject.NewCost}
                        value={currentMany.NewCost}
                        onChange={(e) => handleChangeMany(e)}
                      />
                    </div>
                  </div>

                  {/* <!--nth-child(13) = 14th GRID ITEM = 3rf--> */}
                  <div>
                    <div>
                      {/* <button class="btnAdd">ADD</button> */}

                      <Button
                        label={"Add"}
                        class={"btnAdd"}
                        onClick={addEditManyItem}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- ########-------PRODUCT RECEIVE TABLE DESIGN USING TABLE----###### --> */}

            <div class="subContainer">
              <div class="rcvInfo">
                <CustomTable
                  columns={manyColumnList}
                  rows={manyDataList ? manyDataList : {}}
                  actioncontrol={actioncontrol}
                  ispagination={false}
                />
              </div>

              {/* <!-- ########-----PRODUCT RECEIVE SUBMISSION AND CONFIRMATION AREA----######## --> */}
              <div class="rcvFooter">
                {/* <!-- FOOTER PARTITION-2 OPERATION AREA--> */}
                <div class="footerPartion-2">
                  <Button
                    disabled={false}
                    label={"Back"}
                    class={"btnClose"}
                    onClick={backToList}
                  />

                  <Button
                    disabled={false}
                    label={"Print"}
                    class={"btnPrint"}
                    onClick={printInvoice}
                  />

                  <Button
                    disabled={currentInvoice.BPosted}
                    label={"Save"}
                    class={"btnSave"}
                    onClick={saveData}
                  />

                  <Button
                    disabled={currentInvoice.BPosted}
                    label={"Post"}
                    class={"btnPost"}
                    onClick={postInvoice}
                  />
                </div>

                <div>
                  <label>Invoice Total:</label>
                  <label class={"font-bold"}>
                    {currentInvoice.NetPaymentAmount}
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReceiveReturn;
